import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { fromEvent, debounceTime, distinctUntilChanged, tap } from "rxjs";
import { LayoutUtilsService } from "src/app/core/services/layout-utils.service";
import { LoaderService } from "src/app/core/services/loader.service";
import { RequestService } from "src/app/core/services/request.service";
import { ConfirmDialogComponent } from "src/app/shared/confirm-dialog/confirm-dialog.component";
import { ClientsComponent } from "src/app/shared/modals/clients/clients.component";
import { ModalUploaderDialogComponent } from "src/app/shared/modals/custom-uploader-dialog/custom-uploader-dialog.component";
import { FlowAssignComponent } from "src/app/shared/modals/flow-assign/flow-assign.component";
import { environment } from "src/environments/environment";
import { FlowTable } from "../flow-list/flow-list.component";

export interface CharacterListTable {
    profileImage: string;
    id: string;
    name: string;
    email: string;
}

@Component({
    selector: 'app-clients-list',
    templateUrl: './clients-list.component.html',
    styleUrls: ['./clients-list.component.scss']
})

export class ClientsListComopnent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild('searchInput') searchInput!: ElementRef;

    pageIndex: number = 1;
    pageSize: number = 10;
    displayedUsersColumns: string[] = ['pictureLink', 'name', 'email', 'role', 'tools'];
    displayedClientsColumns: string[] = ['pictureLink', 'name', 'email', 'tools'];
    datasource: any = undefined;
    public defaultPictureLink: string = 'assets/images/profile.png';
    public displayType: string;

    //used for importing users
    public defaultFields: string[] = ['title', 'firstName', 'lastName', 'email', 'gender', 'resources', 'isSuperAdmin', 'pictureLink'];
    public fieldsObject: string[] = JSON.parse(JSON.stringify(this.defaultFields));
    public originalTableSetting: any = undefined;
    public tableSetting: any = undefined;
    public filterObject: any = undefined;
    public type: string = 'user';

    private allUsers: any = [];
    private subscriptions: any = [];

    constructor(private requestService: RequestService, private translate: TranslateService, private layoutUtilsService: LayoutUtilsService, private loadService: LoaderService, private router: Router, private dialog: MatDialog, private activatedRoute: ActivatedRoute) {
    }

    ngOnInit() {
        this.activatedRoute.data.subscribe((data: any) => {
            this.type = data.type;

            if (this.type === 'admin')
                this.displayType = this.translate.instant('Client');
            else
                this.displayType = this.translate.instant('User');

            this.getClients();
            this.buildSetting();
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    ngAfterViewInit(): void {
        this.subscriptions.push(fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
            debounceTime(150),
            distinctUntilChanged(),
            tap(() => {
                try {
                    this.pageIndex = 0;
                    this.getClients(undefined, this.searchInput.nativeElement.value);
                } catch (e) { }
            })
        ).subscribe());
    }

    getClients(event?: PageEvent, term: string = this.searchInput?.nativeElement.value) {
        this.pageIndex = event?.pageIndex || 0;
        this.pageSize = event?.pageSize || 10;

        this.loadService.display(true);
        this.requestService.getDataList('user', {
            page: this.pageIndex + 1, perpage: this.pageSize, term: term || '', filter: {
                "$and": [
                    { "resources._id": { "$in": this.type === 'admin' ? [environment.customKeys.roleOwner] : [environment.customKeys.roleView, environment.customKeys.roleAdmin] } },
                    { 'organizationId._id': { '$eq': this.requestService.orgId } },
                ]
            }, orderBy: 'updatedAt', orderDir: 'desc', fieldKeys: ['email', 'firstName', 'lastName', 'pictureLink', 'resources', 'updatedAt']
        }, (data, error) => {
            if (data) {
                this.paginator.length = data.pagination.total;

                data.results.map(i => {
                    i.role = i.resources[0]._id === environment.customKeys.roleOwner ? 'Owner' : i.resources[0]._id === environment.customKeys.roleView ? 'End User' : 'Admin';
                });

                this.datasource = new MatTableDataSource<CharacterListTable>(data.results);
                // this.datasource.paginator = this.paginator;
                this.allUsers = data.results;
            }
            else if (error) {
                this.layoutUtilsService.showNotification('Error: ' + error, this.translate.instant('Dismiss'));
            }
            this.loadService.display(false);
        });
    }

    deleteClient(id: string) {
        const dialog = this.dialog.open(ConfirmDialogComponent, {
            data: {
                message: this.translate.instant('Are you sure you want to delete this client?'),
                title: ''
            }, width: 'fit-content'
        });
        dialog.afterClosed().subscribe(result => {
            if (result) {
                this.loadService.display(true);
                this.requestService.deleteData('user', id + '/' + this.requestService.orgId, (data, error) => {
                    if (data) {
                        this.layoutUtilsService.showNotification(this.translate.instant('Deleted Successfully'), this.translate.instant('Dismiss'));
                        this.getClients();
                    }
                    else if (error) {
                        this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
                    }
                    this.loadService.display(false);
                });
            }
        });
    }

    addClient() {
        const dialog = this.dialog.open(ClientsComponent, {
            disableClose: true,
            autoFocus: true,
            width: '60vw',
            data: {
                title: this.type === 'admin' ? this.translate.instant('Create a Client') : this.translate.instant('Create a User'),
                type: this.type,
            }
        });
        dialog.afterClosed().subscribe((result: any) => {
            if (result) {
                if (result.redirectTo) {
                    this.router.navigate([result.redirectTo]);
                    this.getClients();
                }
            }
        });
    }

    editClient(client: any) {
        const dialog = this.dialog.open(ClientsComponent, {
            disableClose: true,
            autoFocus: true,
            width: '60vw',
            data: {
                title: this.type === 'admin' ? this.translate.instant('Edit Client') : this.translate.instant('Edit User'),
                id: client._id,
                firstName: client.firstName,
                lastName: client.lastName,
                pictureLink: client.pictureLink || this.defaultPictureLink,
                email: client.email,
                type: this.type,
                resources: client.resources[0]._id
            }
        });
        dialog.afterClosed().subscribe((result: any) => {
            if (result) {
                if (result.redirectTo) {
                    this.router.navigate([result.redirectTo]);
                    this.getClients();
                }
            }
        });
    }

    assignFlows() {
        const dialogRef = this.dialog.open(FlowAssignComponent, {
            width: '60vw',
            disableClose: true,
            data: {
                title: this.translate.instant('Assign Flows'),
                subTitle: this.translate.instant('Select one or more users and assign flows to them. The assigned flows will be available for users automatically.'),
                cancelbtn: 'Cancel',
                confirmbtn: 'Confirm',
            }
        });
        dialogRef.afterClosed().subscribe((res: any) => {
            if (res) {

            }
        });
    }

    public importClients() {
        const dialogRef = this.dialog.open(ModalUploaderDialogComponent, {
            width: '800px',
            disableClose: false,
            autoFocus: false,
            data: {
                dataType: 'user',
                title: this.translate.instant('Upload CSV'),
                modalSetting: JSON.parse(JSON.stringify(this.tableSetting)),
                path: 'user/org/import/',
                addName: false,
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.getClients();
            }
        });
    }

    public importUsers() {
        const dialogRef = this.dialog.open(ModalUploaderDialogComponent, {
            width: '800px',
            disableClose: false,
            autoFocus: false,
            data: {
                dataType: 'user',
                title: this.translate.instant('Upload CSV'),
                modalSetting: JSON.parse(JSON.stringify(this.tableSetting)),
                path: 'user/import/',
                addName: false,
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.getClients();
            }
        });
    }

    // all below methods are used for importing cvs (reference stellar)
    private buildSetting() {
        let fields: any[] = this.fieldsObject;
        this.requestService.getMetaData('user', fields, (data, error) => {
            if (error) {
                this.layoutUtilsService.showNotification('Error: ' + error, 'Dismiss');
            }
            if (data) {
                this.originalTableSetting = Object.assign({}, data.results);
                const copiedItem = JSON.parse(JSON.stringify(this.originalTableSetting));
                this.tableSetting = this.getSetting(copiedItem, this.getCustomFilter());
            }
        });
    }

    private getSetting(data, filters) {
        let tableSetting = data;
        tableSetting['target'] = 'self';
        if (filters)
            tableSetting['filters'] = filters;

        tableSetting['useOrgId'] = true;
        tableSetting['customSettings'] = {
            organizationId: {
                visible: false,
                value: [{ _id: this.requestService.orgId, name: '' }]
            },
            appId: { visible: false, value: [{ _id: this.requestService.appId, organizationId: this.requestService.orgId, name: '' }] },
            locationId: { visible: false, value: [{ _id: this.requestService.locId, appId: this.requestService.appId, name: '' }] }
        };


        let actions: any =
        {
            displayName: this.translate.instant('Actions'), name: 'action', type: 'action', dataType: 'array', orderable: false, actions: [
                { action: 'view', displayName: this.translate.instant('Details'), target: 'parent', icon: 'web_asset' }
            ], editable: false, required: false, visible: true
        }
        if (data.editable) {
            actions.actions.push(
                { action: 'edit', displayName: 'Edit', target: 'self', icon: 'create' }
            )
        }
        if (data.editable) {
            actions.actions.push(
                { action: 'uploadUserImage', displayName: this.translate.instant('Upload') + ' ' + this.translate.instant('User') + ' ' + this.translate.instant('Image'), target: 'parent', icon: 'image' }
            )
        }
        if (data.deletable) {
            actions.actions.push(
                { action: 'delete', displayName: 'Delete', target: 'self', icon: 'delete' }
                // { action: 'delete', displayName: 'Delete', target: 'self', icon: 'delete' , rule: ['isSuperAdmin' , undefined ]}
            )
        }
        let idx = 0;
        for (let fld of tableSetting.fields) {
            if (fld.name === 'pictureLink') {
                let userField = JSON.parse(JSON.stringify(fld));
                userField['visible'] = false;
                tableSetting.fields[idx] = userField;
            }
            idx++;
        }
        tableSetting.fields.push(actions);
        return tableSetting;
    }

    private getCustomFilter() {
        if (this.filterObject) {
            return this.filterObject;
        } else {
            let filters = {};
            filters['$and'] = [{ "organizationId._id": { "$in": [this.requestService.orgId] } }];
            // if(!this.isSuperAdmin){
            //   filters['$and'].push({ "isSuperAdmin": { "$ne": true } });
            // }
            return filters;
        }
    }

    private search(searchText: string) {
        searchText = searchText.toLowerCase();
        this.datasource = new MatTableDataSource<FlowTable>(this.allUsers.filter((i: any) => i.name.toLowerCase().indexOf(searchText) !== -1));
    }
}