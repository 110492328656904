import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { LayoutUtilsService } from "src/app/core/services/layout-utils.service";
import { RequestService } from "src/app/core/services/request.service";

@Component({
    selector: 'app-opening-acts',
    templateUrl: './opening-acts.component.html',
    styleUrls: ['./opening-acts.component.scss']
})

export class OpeningActsComponent implements OnInit, OnDestroy {

    // name: string = '';
    // maleContent: string = '';
    // femaleContent: string = '';
    // otherContent: string = '';
    openingActId: string = '';
    loading: boolean = false;
    private subscriptions: any[] = <any>[];

    nameFormControl = new FormControl('', Validators.required);
    maleContentFormControl = new FormControl('', Validators.required);
    femaleContentFormControl = new FormControl('', Validators.required);
    otherContentFormControl = new FormControl('', Validators.required);

    constructor(private requestService: RequestService, private layoutUtilsService: LayoutUtilsService, private translate: TranslateService, private dialogRef: MatDialogRef<OpeningActsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        if (data.id) {
            this.openingActId = data.id;
            this.getOpeningAct(this.openingActId);
        }
    }

    ngOnInit() {
    }

    ngOnDestroy() {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    getOpeningAct(id: string) {
        this.loading = true;
        this.requestService.getRecord(id, 'openingacts', (data: any, error: any) => {
            // console.log(data, error)
            this.nameFormControl.setValue(data.results.name);
            this.maleContentFormControl.setValue(data.results.maleContent);
            this.femaleContentFormControl.setValue(data.results.femaleContent);
            this.otherContentFormControl.setValue(data.results.otherContent);
            this.loading = false;
        });
    }

    private validate(): boolean {
        this.nameFormControl.markAsTouched();
        this.maleContentFormControl.markAsTouched();
        this.femaleContentFormControl.markAsTouched();
        this.otherContentFormControl.markAsTouched();
        return this.nameFormControl.hasError('required') || this.maleContentFormControl.hasError('required') || this.femaleContentFormControl.hasError('required') || this.otherContentFormControl.hasError('required');
    }

    saveOpeningAct() {
        if (!this.validate()) {
            this.loading = true;
            this.requestService.saveRecord({
                name: this.nameFormControl.value,
                organizationId: this.requestService.orgId,
                maleContent: this.maleContentFormControl.value,
                femaleContent: this.femaleContentFormControl.value,
                otherContent: this.otherContentFormControl.value
            }, 'openingacts', (data: any, error: any) => {
                // console.log(data, error)

                if (data) {
                    this.dialogRef.close({ redirectTo: '/openingActs/list' });
                    this.layoutUtilsService.showNotification(this.translate.instant('Saved Successfully'), this.translate.instant('Dismiss'));
                }
                if (error)
                    this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
                this.loading = false;
            });
        }
    }

    updateOpeningAct() {
        if (!this.validate()) {
            this.loading = true;
            this.requestService.updateRecord({
                name: this.nameFormControl.value,
                organizationId: this.requestService.orgId,
                maleContent: this.maleContentFormControl.value,
                femaleContent: this.femaleContentFormControl.value,
                otherContent: this.otherContentFormControl.value
            }, 'openingacts', this.openingActId, (data: any, error: any) => {
                // console.log(data, error)
                if (data) {
                    this.dialogRef.close({ redirectTo: '/openingActs/list' });
                    this.layoutUtilsService.showNotification(this.translate.instant('Updated Successfully'), this.translate.instant('Dismiss'));
                }
                if (error)
                    this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
                this.loading = false;
            });
        }
    }

    insertSpecialString(obj: any, control: FormControl) {
        let cursorPosition = obj.selectionStart;
        obj.value = [obj.value.slice(0, cursorPosition), "[X] ", obj.value.slice(cursorPosition)].join('');
        setTimeout(() => { cursorPosition = obj.selectionEnd = cursorPosition + 4; }, 0);
        control?.patchValue(obj.value);
    }

    closeDialog(data?: any) {
        this.dialogRef.close(data);
    }
}