import { ErrorHandler, Inject, Injectable, InjectionToken, Injector } from '@angular/core';
import * as Rollbar from 'rollbar';
import { environment } from 'src/environments/environment';
import { VERSION } from 'src/version';
import { AuthenticationService } from './auth.service';

let rollbarConfig = {
    accessToken: environment.rollbar.apiKey,
    captureUncaught: true,
    captureUnhandledRejections: true,
    enabled: environment.environment === 'local' ? false : true,
    payload: {
        environment: environment.environment,
        client: {
            javascript: {
                source_map_enabled: true,
                code_version: VERSION.git,
                guess_uncaught_frames: true,
            }
        }
    }
};

export function rollbarFactory() {
    return new Rollbar(rollbarConfig);
}

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    currentUser: any = undefined;

    constructor(private injector: Injector, @Inject(RollbarService) private rollbar: Rollbar, private authService: AuthenticationService) {
        this.currentUser = this.authService.getCurrentUser();
    }

    handleError(error: Error) {
        // Obtain dependencies at the time of the error
        // This is because the GlobalErrorHandler is registered first
        // which prevents constructor dependency injection
        if (this.currentUser)
            this.rollbar.configure({
                payload: {
                    person: {
                        id: this.currentUser._id,
                        email: this.currentUser.email,
                        name: this.currentUser.name
                    }
                }
            });

        this.rollbar.error(error);

        // Re-throw the error
        throw error;
    }
}
