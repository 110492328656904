<div class="kt-portlet">
    <div class="kt-portlet__head kt-portlet__head__custom">
        <div class="kt-portlet__head-label">
            <h3>{{data.title}}</h3>
            <div *ngIf="data.subTitle" class="sub-title">{{data.subTitle}}</div>
        </div>
    </div>
    <div class="kt-form">
        <div class="kt-portlet__body container">
            <div fxLayout="row" fxLayoutGap="0.5vw">
                <div fxLayout="row" fxLayoutGap="1vw" style="width: 100%;">
                    <div fxLayout="column" fxFlex="50">
                        <div fxFlex class="top-section" fxGrow="0">
                            <mat-checkbox fxFlex [disableRipple]="true" [(ngModel)]="selectedAllUsersChecked"
                                (change)="selectAllUsers()" [disabled]="loadingUsers">
                                <span class="label">
                                    {{'Users' | translate}}
                                </span>
                            </mat-checkbox>
                            <div fxFlex class="search">
                                <input matInput type="text" #usersearch>
                                <mat-icon>search</mat-icon>
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex fxLayoutGap="0.5vh" class="list">
                            <mat-spinner [diameter]="30" class="loader" *ngIf="loadingUsers"></mat-spinner>
                            <ng-container *ngIf="!loadingUsers">
                                <div *ngFor="let user of users" fxLayout="row" class="row"
                                    [ngStyle]="{'background-color': selectedUser === user._id ? '#e6e4e4' : ''}">
                                    <mat-checkbox [disableRipple]="true" [(ngModel)]="user.checked"
                                        (ngModelChange)="validateButtons(user)">
                                    </mat-checkbox>
                                    <div fxFlex class="user-name" (click)="highlightAssignments(user._id)">
                                        {{user.name}}
                                        <div class="email">{{user.email}}</div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex="50">
                        <div fxFlex class="top-section" fxGrow="0" fxLayout="row">
                            <mat-checkbox fxFlex [disableRipple]="true" [(ngModel)]="selectedAllFlowsChecked"
                                (change)="selectAllFlows()" [disabled]="loadingFlows"><span class="label">
                                    {{'Flows' | translate}}
                                </span>
                            </mat-checkbox>
                            <div fxFlex class="search">
                                <input matInput type="text" #flowsearch>
                                <mat-icon>search</mat-icon>
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex fxLayoutGap="0.5vh" class="list">
                            <mat-spinner [diameter]="30" class="loader" *ngIf="loadingFlows"></mat-spinner>
                            <ng-container *ngIf="!loadingFlows">
                                <div fxLayout="row" *ngFor="let flow of flows" class="row"
                                    [ngStyle]="{'background-color': assignedFlows?.includes(flow._id) ? '#e6e4e4' : ''}">
                                    <mat-checkbox [disableRipple]="true" [(ngModel)]="flow.checked"
                                        (ngModelChange)="validateButtons()">
                                    </mat-checkbox>
                                    <div fxFlex>
                                        {{flow.name}}
                                        <div class="sub">{{'Version' | translate}}: {{flow.versionId}}</div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <div style="width: 50%;">
                <div fxLayout="column" fxLayoutGap="0.5vh">
                    <mat-form-field appearance="fill" fxFlex>
                        <mat-label>{{'Due Date' | translate}}</mat-label>
                        <input matInput [matDatepicker]="picker" [min]="todaysDate" [(ngModel)]="dueDate">
                        <mat-hint>MM DD YYYY</mat-hint>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field appearance="fill" fxFlex>
                        <mat-select [(ngModel)]="isMultiple">
                            <mat-option value="single">{{'Single submissions' | translate}}</mat-option>
                            <mat-option value="multiple">{{'Multiple submissions' | translate}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit text-right">
            <div class="kt-form__actions kt-form__actions--solid">
                <br />
                <button mat-raised-button (click)="closeDialog()">{{'Cancel' |
                    translate}}</button>&nbsp;
                <button mat-raised-button color="primary"
                    [disabled]="flows.length == 0 || users.length == 0 || assignedFlows.length == 0 || !enableButtons"
                    (click)="saveAssignments(false)">{{'Unassign' |
                    translate}}</button>&nbsp;
                <button mat-raised-button color="primary"
                    [disabled]="flows.length == 0 || users.length == 0 || !enableButtons"
                    (click)="saveAssignments(true)">{{'Assign' |
                    translate}}</button>
            </div>
        </div>
    </div>
</div>