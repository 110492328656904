<div class="kt-portlet">
    <div class="kt-portlet__head kt-portlet__head__custom">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">{{data.title}}</h3>
            <span class="kt-portlet__head-icon kt-hide">
                <i class="la la-gear"></i>
            </span>
        </div>
    </div>
    <div class="kt-form">
        <div class="kt-portlet__body">
            <mat-spinner [diameter]="50" class="loader" *ngIf="loading"></mat-spinner>
            <div class="container" *ngIf="!loading">
                <div fxLayout="column" fxLayoutGap="0.5vw">
                    <mat-form-field appearance="fill" fxFlex>
                        <mat-label>{{'Title' | translate}}</mat-label>
                        <input matInput [(ngModel)]="title" placeholder="">
                    </mat-form-field>
                    <mat-form-field fxFlex appearance="fill">
                        <mat-label>{{'Tags' | translate}}</mat-label>
                        <mat-chip-list #chipList aria-label="Tag Selection">
                            <mat-chip *ngFor="let tag of tags" (removed)="remove(tag)">
                                {{tag.name}}
                                <button matChipRemove>
                                    <mat-icon>cancel</mat-icon>
                                </button>
                            </mat-chip>
                            <input placeholder="{{'New tag...' | translate}}" [matChipInputFor]="chipList"
                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
                                (matChipInputTokenEnd)="add($event)">
                        </mat-chip-list>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit text-right">
            <div class="kt-form__actions kt-form__actions--solid">
                <br />
                <button mat-raised-button (click)="closeDialog()">{{'Cancel' |
                    translate}}</button>&nbsp;
                <button mat-raised-button color="primary" (click)="saveTags()">{{'Save' |
                    translate}}</button>
            </div>
        </div>
    </div>
</div>