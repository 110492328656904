import { Component } from '@angular/core';

@Component({
  selector: 'app-content-placeholder-animation',
  templateUrl: './content-placeholder-animation.component.html',
  styleUrls: ['./content-placeholder-animation.component.css']
})
export class ContentPlaceholderAnimationComponent {

  constructor() { }

}
