import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './core/guards/auth.guard';
import { CanDeactivateDiagramGuard } from './core/guards/canDeactivateDiagram.guard';
import { CharactersListComopnent } from './features/dashboard/characters-list/characters-list.component';
import { ClientsListComopnent } from './features/dashboard/clients/clients-list.component';
import { DiagramComponent } from './features/dashboard/diagram/diagram.component';
import { FlowListComponent } from './features/dashboard/flow-list/flow-list.component';
import { OpeningActsListComponent } from './features/dashboard/opening-acts-list/opening-acts-list.component';
import { LayoutComponent } from './shared/layout/layout.component';

const appRoutes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: '', component: FlowListComponent },
      { path: 'diagram/:id', component: DiagramComponent, canDeactivate: [CanDeactivateDiagramGuard] },
      { path: 'openingActs/list', component: OpeningActsListComponent },
      { path: 'characters/list', component: CharactersListComopnent },
      { path: 'clients/list', component: ClientsListComopnent, data: { type: 'admin' } },
      { path: 'users/list', component: ClientsListComopnent, data: { type: 'user' } },
    ]
  },
  {
    path: 'auth',
    loadChildren: () => import('./features/auth/auth.module').then(m => m.AuthModule),
  },
  // {
  //   path: 'dashboard',
  //   loadChildren: () => import('./features/dashboard/dashboard.module').then(m => m.DashboardModule),
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path: '**',
  //   redirectTo: 'dashboard',
  //   pathMatch: 'full'
  // },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes)
  ],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
