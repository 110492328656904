import { Component, OnInit, ChangeDetectorRef, OnDestroy, AfterViewInit } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';

import { AuthenticationService } from 'src/app/core/services/auth.service';
import { SpinnerService } from '../../core/services/spinner.service';
import { NavigationEnd, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { RequestService } from 'src/app/core/services/request.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { environment } from 'src/environments/environment';
import { LayoutUtilsService } from 'src/app/core/services/layout-utils.service';
import { FormControl } from '@angular/forms';
import { DiagramService } from 'src/app/core/services/diagram.service';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy, AfterViewInit {

    private _mobileQueryListener: () => void;
    mobileQuery: MediaQueryList;
    userName: string = "";
    public currentUser: any;
    isAdmin: boolean = false;
    isSuperAdmin: boolean = false;
    currentRoute: string = '';
    clients: any = [];
    selectedClient: FormControl = new FormControl('');
    // selectedClientName: string = undefined;
    private subscriptions: any[] = <any>[];
    // private autoLogoutSubscription: Subscription = new Subscription;

    constructor(private changeDetectorRef: ChangeDetectorRef,
        private media: MediaMatcher,
        public spinnerService: SpinnerService,
        private authService: AuthenticationService, private layoutUtilsService: LayoutUtilsService,
        private diagramService: DiagramService, private router: Router, private dialog: MatDialog, private translate: TranslateService, private requestService: RequestService, private loadService: LoaderService) {

        this.mobileQuery = this.media.matchMedia('(max-width: 1000px)');
        this._mobileQueryListener = () => changeDetectorRef.detectChanges();
        // tslint:disable-next-line: deprecation
        this.mobileQuery.addListener(this._mobileQueryListener);

        router.events.subscribe(data => {
            if (data instanceof NavigationEnd) {
                this.currentRoute = data.url;
            }
        });
    }

    ngOnInit(): void {
        this.subscriptions.push(this.requestService.currentUserSubject.subscribe((user) => {
            if (user) {
                this.currentUser = user;
                this.isAdmin = user.resources.find(i => i._id === environment.customKeys.roleAdmin);
                this.isSuperAdmin = user.isSuperAdmin;
                this.userName = user.fullName;

                let orgIds = user.resources.filter(i => i._id === environment.customKeys.roleAdmin).map(j => j.organizationId);
                this.loadOrgs(orgIds);
            } else {
                setTimeout(() => {
                    this.requestService.logout();
                }, 200);
            }
        }));

        this.subscriptions.push(this.selectedClient.valueChanges.subscribe(value => {
            this.switchDashboard(value);
            // this.selectedClientName = this.clients.find(i => i._id === value)?.name;
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
        // tslint:disable-next-line: deprecation
        this.mobileQuery.removeListener(this._mobileQueryListener);
        // this.autoLogoutSubscription.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.changeDetectorRef.detectChanges();
    }

    loadOrgs(orgIds: []) {
        this.requestService.getDataList('organization', {
            page: 1, term: '', filter: {
                "$and": [
                    { "_id": { "$in": orgIds } },
                    { "_id": { "$ne": this.requestService.orgId } }
                ]
            }, orderBy: 'name', orderDir: 'asc', fieldKeys: ['name']
        }, (data, error) => {
            if (data) {
                this.clients = data.results;
            }
            else if (error) {
                this.layoutUtilsService.showNotification('Error: ' + error, this.translate.instant('Dismiss'));
            }
            this.loadService.display(false);
        });
    }

    // loadUsers(orgIds: []) {
    //     this.requestService.getDataList('user', {
    //         page: 1, term: '', filter: {
    //             "$and": [
    //                 { "resources._id": { "$eq": environment.customKeys.roleAdmin } },
    //                 { "organization._id": { "$in": orgIds } },
    //                 { "_id": { "$ne": this.currentUser._id } },
    //             ]
    //         }, orderBy: 'name', orderDir: 'asc', fieldKeys: ['email', 'name']
    //     }, (data, error) => {
    //         if (data) {
    //             this.clients = data.results;
    //         }
    //         else if (error) {
    //             this.layoutUtilsService.showNotification('Error: ' + error, this.translate.instant('Dismiss'));
    //         }
    //         this.loadService.display(false);
    //     });
    // }
    onLoggedout() {
        if (!this.diagramService.diagramChangedLock.getValue()) {
            this.loadService.display(true);
            this.requestService.logOutApi();
        }
        else {
            this.diagramService.diagramChangedSubject.next({ url: '/auth/login' });
        }
    }

    switchDashboard(id: string) {
        this.requestService.selectedClientIdForDashboard = id;

        if (this.currentRoute === '/')
            this.requestService.reloadFlowList.next(true);
        else
            this.router.navigate(['']);
    }
}
