import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { LayoutUtilsService } from "src/app/core/services/layout-utils.service";
import { LoaderService } from "src/app/core/services/loader.service";
import { RequestService } from "src/app/core/services/request.service";
import { ConfirmDialogComponent } from "src/app/shared/confirm-dialog/confirm-dialog.component";
import { OpeningActsComponent } from "src/app/shared/modals/opening-acts/opening-acts.component";

export interface OpeningActTable {
    id: string;
    name: string;
    maleContent: string;
    femaleContent: string;
    otherContent: string;
}

@Component({
    selector: 'app-opening-acts-list',
    templateUrl: './opening-acts-list.component.html',
    styleUrls: ['./opening-acts-list.component.scss']
})

export class OpeningActsListComponent implements OnInit {
    @ViewChild(MatPaginator) paginator!: MatPaginator;

    displayedColumns: string[] = ['name', 'tools'];
    datasource: any = undefined;

    constructor(private requestService: RequestService, private translate: TranslateService, private layoutUtilsService: LayoutUtilsService, private loadService: LoaderService, private router: Router, private dialog: MatDialog) {
    }

    ngOnInit() {
        this.getOpeningActs();
    }

    getOpeningActs() {
        this.loadService.display(true);
        this.requestService.getDataListByOrgByGet('openingacts', (data, error) => {
            if (error) {
                this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
            }
            if (data) {
                // console.log('opening acts', data.results)
                this.datasource = new MatTableDataSource<OpeningActTable>(data.results);
                this.datasource.paginator = this.paginator;
            }
            this.loadService.display(false);
        });
    }

    deleteOpeningAct(id: string) {
        const dialog = this.dialog.open(ConfirmDialogComponent, {
            data: {
                message: this.translate.instant('Are you sure you want to delete this opening act?'),
                title: ''
            }, width: 'fit-content'
        });
        dialog.afterClosed().subscribe(result => {
            if (result) {
                this.loadService.display(true);
                this.requestService.deleteData('openingacts', id, (data, error) => {
                    if (data) {
                        this.layoutUtilsService.showNotification(this.translate.instant('Deleted Successfully'), this.translate.instant('Dismiss'));
                        this.getOpeningActs();
                    }
                    else if (error) {
                        this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
                    }
                    this.loadService.display(false);
                });
            }
        });
    }

    openOpeningActs() {
        const dialog = this.dialog.open(OpeningActsComponent, {
            disableClose: true,
            autoFocus: true,
            width: '60vw',
            data: {
                title: 'Create an Opening Act'
            }
        });
        dialog.afterClosed().subscribe((result: any) => {
            if (result) {
                if (result.redirectTo) {
                    this.router.navigate([result.redirectTo]);
                    this.getOpeningActs();
                }
            }
        });
    }

    editOpeningAct(id: string) {
        const dialog = this.dialog.open(OpeningActsComponent, {
            disableClose: true,
            autoFocus: true,
            width: '60vw',
            data: {
                title: 'Edit Opening Act',
                id: id
            }
        });
        dialog.afterClosed().subscribe((result: any) => {
            if (result) {
                if (result.redirectTo) {
                    this.router.navigate([result.redirectTo]);
                    this.getOpeningActs();
                }
            }
        });
    }
}