import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { AuthenticationService } from "src/app/core/services/auth.service";
import { LayoutUtilsService } from "src/app/core/services/layout-utils.service";
import { RequestService } from "src/app/core/services/request.service";
import { ConfirmDialogComponent } from "src/app/shared/confirm-dialog/confirm-dialog.component";

@Component({
    selector: 'app-flow',
    templateUrl: './flow.component.html',
    styleUrls: ['./flow.component.scss']
})

export class FlowComponent implements OnInit, OnDestroy {
    openingActs: string[] = [];
    characters: string[] = [];
    _id: string = '';
    version: string = '';
    flowId: string = '';
    flowCount: number = 1;
    isDefault: boolean = true;
    flow: any = {};
    loading: boolean = false;
    fileLink: string = '';
    currentUser: any;
    allowReflection: boolean = false;
    allowDecisionFeedback: boolean = false;

    nameFormControl = new FormControl('', [Validators.required]);
    openingActFormControl = new FormControl('');
    charactersFormControl = new FormControl('');
    descriptionFormControl = new FormControl('', [Validators.required]);
    openingActAvailabilityFormControl = new FormControl('');
    characterAvailabilityFormControl = new FormControl('');
    averageCompletionFormControl = new FormControl('', [Validators.min(1), Validators.pattern(/^-?\d+$/)]);

    private subscriptions: any[] = <any>[];

    constructor(private requestService: RequestService, private layoutUtilsService: LayoutUtilsService, private translate: TranslateService, private dialogRef: MatDialogRef<FlowComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog, private authService: AuthenticationService) {
        if (data.id) {
            this._id = data.id;
            this.getFlow(this._id);
        }
        this.getOpeningActs();
        this.getCharacters();
    }

    ngOnInit() {
        this.subscriptions.push(this.requestService.currentUserSubject.subscribe((value) => {
            if (value) {
                this.currentUser = value;
            }
        }));
    }

    ngOnDestroy() {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    getOpeningActs() {
        this.loading = true;
        this.requestService.getDataListByOrgByGet('openingacts', (data, error) => {
            if (error) {
                this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
            }
            if (data) {
                this.openingActs = data.results;
            }
            this.loading = false;
        });
    }

    getCharacters() {
        this.loading = true;
        this.requestService.getDataListByOrgByGet('character', (data, error) => {
            if (error) {
                this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
            }
            if (data) {
                // console.log('opening acts', data.results)
                this.characters = data.results;
            }
            this.loading = false;
        });
    }

    countFlow(flowId: string) {
        this.requestService.getDataList('flow', { page: 1, term: '', filter: { "$and": [{ "organizationId._id": { "$eq": this.requestService.orgId } }, { "flowId": { "$eq": flowId } }] }, orderBy: 'name', orderDir: 'asc', fieldKeys: ['name', 'versionId'] }, (data, error) => {
            if (error) { }
            if (data) {
                let versions = [];
                data.results.forEach(element => {
                    versions.push(element.versionId);
                });
                this.flowCount = Math.max(...versions);
            }
        });
    }

    getFlow(id: string) {
        this.loading = true;
        this.requestService.getRecord(id, 'flow', (data: any, error: any) => {
            // console.log(data, error)
            this.nameFormControl.setValue(data.results.name);
            this.flowId = data.results.flowId;
            this.isDefault = data.results.default;
            this.allowReflection = data.results.allowReflection;
            this.allowDecisionFeedback = data.results.allowDecisionFeedback;
            this.countFlow(this.flowId);
            this.version = data.results.versionId;
            this.flow = data.results.flow;
            this.fileLink = data.results.fileLink;
            this.openingActFormControl.setValue(data.results.openingActIds);
            this.charactersFormControl.setValue(data.results.characterIds);
            this.descriptionFormControl.setValue(data.results.description);
            this.openingActAvailabilityFormControl.setValue(data.results.optactsAvailability === 'randomize' ? 'random' : 'userselection');
            this.characterAvailabilityFormControl.setValue(data.results.charactersAvailability === 'randomize' ? 'random' : 'userselection');
            this.averageCompletionFormControl.setValue(data.results.averageCompletion);
            this.loading = false;
        });
    }

    private validate(): boolean {
        this.nameFormControl.markAsTouched();
        this.openingActFormControl.markAsTouched();
        this.charactersFormControl.markAsTouched();
        this.descriptionFormControl.markAsTouched();
        return this.nameFormControl.hasError('required') || this.descriptionFormControl.hasError('required');
    }

    saveFlow(defaultVersion: boolean = true, versionId: string = '1', flowId: string = new Date().getTime() + (Math.random() + 1).toString(36).substring(7), redirectToDiagram: boolean = true) {
        if (!this.validate()) {
            this.loading = true;

            let flow = {};
            if (flowId)
                flow = this.flow;

            let createdById: string = this.currentUser._id;
            if (this.requestService.selectedClientIdForDashboard)
                createdById = this.requestService.selectedClientIdForDashboard;

            this.requestService.saveRecord({
                versionId: versionId,
                default: defaultVersion,
                name: this.nameFormControl.value!.trim(),
                flowId: flowId,
                fileLink: this.fileLink,
                allowReflection: this.allowReflection,
                allowDecisionFeedback: this.allowDecisionFeedback,
                flow: flow,
                createdBy: createdById,
                openingActIds: this.openingActFormControl.value || [],
                characterIds: this.charactersFormControl.value || [],
                description: this.descriptionFormControl.value,
                optactsAvailability: this.openingActAvailabilityFormControl.value === 'random' ? 'randomize' : 'allow_user_selection',
                charactersAvailability: this.characterAvailabilityFormControl.value === 'random' ? 'randomize' : 'allow_user_selection',
                averageCompletion: this.averageCompletionFormControl.value,
                organizationId: {
                    _id: this.requestService.orgId,
                    name: this.requestService.orgName
                }
            }, 'flow', (data: any, error: any) => {
                // console.log(data, error)

                if (data) {
                    if (redirectToDiagram)
                        this.dialogRef.close({ redirectTo: '/diagram/' + data.results._id });
                    else
                        this.dialogRef.close({ redirectTo: '/' });
                }
                else {
                    this.layoutUtilsService.showNotification(this.translate.instant('Failed: a flow with the same name and version exists.') + error, this.translate.instant('Dismiss'));
                }
                this.loading = false;
            });
        }
    }

    updateFlow() {
        if (!this.validate()) {
            this.loading = true;
            this.requestService.getDataListSummary('instance', { page: 1, term: '', filter: { "$and": [{ "organizationId._id": { "$eq": this.requestService.orgId } }, { "flowId._id": { "$eq": this._id } }] }, orderBy: 'name', orderDir: 'asc', fieldKeys: ['name'], termfields: ['name'] }, (data, error) => {
                if (error) { }
                if (data) {
                    if (data.results.results.length === 0) {
                        const dialog = this.dialog.open(ConfirmDialogComponent, {
                            data: {
                                title: this.translate.instant('Update Flow'),
                                message: this.translate.instant('Do you want to save the update in a new version of the flow or update the existing version?'),
                                noButton: this.translate.instant('Update existing version'),
                                yesButton: this.translate.instant('Save in a new version'),
                                cancelButton: this.translate.instant('Cancel')
                            }, width: 'fit-content', disableClose: true
                        });
                        dialog.afterClosed().subscribe(result => {
                            if (result === true) {
                                this.saveFlow(false, (this.flowCount + 1) + '', this.flowId, false);
                            }
                            else if (result === false) {
                                this.requestService.updateRecord({
                                    name: this.nameFormControl.value!.trim(),
                                    openingActIds: this.openingActFormControl.value,
                                    characterIds: this.charactersFormControl.value,
                                    description: this.descriptionFormControl.value,
                                    default: this.isDefault,
                                    allowDecisionFeedback: this.allowDecisionFeedback,
                                    allowReflection: this.allowReflection,
                                    optactsAvailability: this.openingActAvailabilityFormControl.value === 'random' ? 'randomize' : 'allow_user_selection',
                                    charactersAvailability: this.characterAvailabilityFormControl.value === 'random' ? 'randomize' : 'allow_user_selection',
                                    averageCompletion: this.averageCompletionFormControl.value,
                                }, 'flow', this._id, (data: any, error: any) => {
                                    // console.log(data, error)

                                    if (data) {
                                        this.dialogRef.close({ redirectTo: '/' });
                                        this.layoutUtilsService.showNotification(this.translate.instant('Updated Successfully'), this.translate.instant('Dismiss'));
                                    }
                                    else {
                                        this.layoutUtilsService.showNotification(this.translate.instant('Failed: a flow with the same name and version exists.') + error, this.translate.instant('Dismiss'));
                                    }
                                    this.loading = false;
                                });
                            }
                            else {
                                this.loading = false;
                            }
                        });
                    }
                    else {
                        const dialog = this.dialog.open(ConfirmDialogComponent, {
                            data: {
                                title: this.translate.instant('Updating this flow will create a new version because there are existing submissions related to it. '),
                                message: ''
                            }, width: 'fit-content', disableClose: true
                        });
                        dialog.afterClosed().subscribe((result: any) => {
                            if (result) {
                                this.saveFlow(false, (this.flowCount + 1) + '', this.flowId, false);
                            }
                            else {
                                this.loading = false;
                            }
                        });
                    }
                }
            });
        }
    }

    compareObjects(o1: any, o2: any): boolean {
        return o1.name === o2.name && o1._id === o2._id;
    }

    closeDialog(data?: any) {
        this.dialogRef.close(data);
    }
}