import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { RequestService } from 'src/app/core/services/request.service';
import { TranslateService } from '@ngx-translate/core';
import { LayoutUtilsService } from 'src/app/core/services/layout-utils.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { ConfirmInputEntityDialogComponent } from 'src/app/shared/modals';
import { FlowComponent } from 'src/app/shared/modals/flow/flow.component';
import { TagsComponent } from 'src/app/shared/modals/tags/tags.component';
import { fromEvent, debounceTime, distinctUntilChanged, tap } from 'rxjs';

export interface FlowTable {
  id: string;
  name: string;
  version: string;
  openingAct: string;
  character: string;
  default: string;
}

@Component({
  selector: 'app-flow-list',
  templateUrl: './flow-list.component.html',
  styleUrls: ['./flow-list.component.scss']
})
export class FlowListComponent implements OnInit, AfterViewInit, OnDestroy {
  currentUser: any;
  flowTableDisplayedColumns: string[] = ['name', 'description', 'openingAct', 'character', 'version', 'flow', 'default', 'tools'];
  flowTableDatasource: any = undefined;
  pageIndex: number = 1;
  pageSize: number = 2;

  private allFlows: any = [];
  private subscriptions: any = [];

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild('searchInput') searchInput!: ElementRef;

  constructor(private authService: AuthenticationService, private titleService: Title, private requestService: RequestService, private translate: TranslateService, private layoutUtilsService: LayoutUtilsService, private loadService: LoaderService, private router: Router, private dialog: MatDialog) {
  }

  ngOnInit() {

    this.titleService.setTitle('Flow Engine');
    // this.logger.log('Dashboard loaded');

    // setTimeout(() => {
    //   this.notificationService.openSnackBar('Welcome!');
    // });



    this.subscriptions.push(this.requestService.currentUserSubject.subscribe((value) => {
      if (value) {
        this.currentUser = value;
        this.getFlows();
      }
    }));
    this.subscriptions.push(this.requestService.reloadFlowList.subscribe((value) => {
      if (value)
        this.getFlows();
    }));
  }

  ngAfterViewInit() {
    this.subscriptions.push(fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
      debounceTime(150),
      distinctUntilChanged(),
      tap(() => {
        try {
          this.pageIndex = 0;
          this.getFlows(undefined, this.searchInput.nativeElement.value);
        } catch (e) { }
      })
    ).subscribe());
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  getFlows(event?: PageEvent, term: string = this.searchInput?.nativeElement.value) {
    this.pageIndex = event?.pageIndex || 0;
    this.pageSize = event?.pageSize || 10;

    let id: string = this.requestService.orgId;
    if (this.requestService.selectedClientIdForDashboard)
      id = this.requestService.selectedClientIdForDashboard;

    this.loadService.display(true);
    this.requestService.getDataList('flow', {
      page: this.pageIndex + 1, perpage: this.pageSize, term: term || '', termfields: ['name'], filter: {
        "$and": [
          // { "createdBy": { "$eq": id } },
          { 'organizationId._id': { '$eq': id } },
        ]
      }, orderBy: 'updatedAt', orderDir: 'desc', fieldKeys: ['_id', 'flowId', 'name', 'flow', 'openingActIds', 'characterIds', 'default', 'description', 'versionId', 'tags', 'updatedAt']
    }, (data, error) => {
      if (data) {
        this.paginator.length = data.pagination.total;
        let getSortedFlowIds: any = [];
        let sortedData: any = [];
        let alternatingColors = ['#FFFFFF', '#E8E8E8'];

        data.results.forEach((element: any) => {
          if (!getSortedFlowIds.find((i: any) => i === element.flowId)) {
            getSortedFlowIds.push(element.flowId);
            sortedData = sortedData.concat(data.results.filter((j: any) => j.flowId === element.flowId));
          }
        });

        // console.log('getSortedFlowIds', getSortedFlowIds, sortedData)

        sortedData.map((element: any) => {
          element.backgroundColor = alternatingColors[(getSortedFlowIds.findIndex((i: any) => i === element.flowId) % 2)];

          if (element.flow && !Object.keys(element.flow).length) {
            element.flow = '';
          }

          element.openingAct = []
          element.openingActIds?.forEach((openingAct: any) => {
            element.openingAct.push(openingAct.name);
          });
          element.openingAct = element.openingAct.join(', ');

          element.character = []
          element.characterIds?.forEach((character: any) => {
            element.character.push(character.name);
          });
          element.character = element.character.join(', ');
        });
        // console.log('flows', data.results)
        this.flowTableDatasource = new MatTableDataSource<FlowTable>(sortedData);
        // this.flowTableDatasource.paginator = this.paginator;
        this.allFlows = sortedData;
        this.loadService.display(false);
      }
      if (error) {
        this.layoutUtilsService.showNotification('Error: ' + error, this.translate.instant('Dismiss'));
      }
      this.loadService.display(false);
    });
  }

  deleteFlow(id: string) {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        message: this.translate.instant('Are you sure you want to delete this flow?'),
        title: ''
      }, width: 'fit-content'
    });
    dialog.afterClosed().subscribe(result => {
      if (result) {
        this.loadService.display(true);
        this.requestService.deleteData('flow', id, (data, error) => {
          if (data) {
            this.layoutUtilsService.showNotification(this.translate.instant('Deleted Successfully'), this.translate.instant('Dismiss'));
            this.getFlows();
          }
          else if (error) {
            this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
          }
          this.loadService.display(false);
        });
      }
    });
  }

  editDiagram(id: string) {
    this.router.navigate(['/diagram/' + id]);
  }

  copyFlow(id: string, name: string) {
    const dialog = this.dialog.open(ConfirmInputEntityDialogComponent, {
      data: {
        title: this.translate.instant('Copy ' + name),
        description: this.translate.instant('Flow Name'),
        confirmbtn: this.translate.instant('Submit'),
        cancelbtn: this.translate.instant('Cancel')
      }, width: '40vw', disableClose: true
    });
    dialog.afterClosed().subscribe(result => {
      if (result) {
        this.loadService.display(true);
        this.requestService.getRecord(id, 'flow', (data: any, error: any) => {
          this.requestService.saveRecord({
            versionId: '1',
            default: true,
            name: result,
            flowId: new Date().getTime() + (Math.random() + 1).toString(36).substring(7),
            flow: data.results.flow,
            openingActIds: data.results.openingActIds,
            fileLink: data.results.fileLink,
            characterIds: data.results.characterIds,
            description: data.results.description,
            optactsAvailability: data.results.optactsAvailability,
            charactersAvailability: data.results.charactersAvailability,
            averageCompletion: data.results.averageCompletion,
            allowDecisionFeedback: data.results.allowDecisionFeedback,
            allowReflection: data.results.allowReflection,
            tags: data.results.tags,
            organizationId: {
              _id: this.requestService.orgId,
              name: this.requestService.orgName
            }
          }, 'flow', (data: any, error: any) => {
            // console.log(data, error)

            if (data) {
              this.layoutUtilsService.showNotification(this.translate.instant('Copied Successfully'), this.translate.instant('Dismiss'));
              this.getFlows();
            }
            else {
              this.layoutUtilsService.showNotification(this.translate.instant('Failed to copy') + error, this.translate.instant('Dismiss'));
            }
            this.loadService.display(false);
          });
        });
      }
    });
  }

  openCreateFlow() {
    const dialog = this.dialog.open(FlowComponent, {
      disableClose: true,
      autoFocus: true,
      width: '60vw',
      data: {
        title: 'Create a Flow'
      }
    });
    dialog.afterClosed().subscribe((result: any) => {
      if (result) {
        if (result.redirectTo) {
          this.router.navigate([result.redirectTo]);
          // this.getFlows();
        }
      }
    });
  }

  openEditFlow(id: string) {
    const dialog = this.dialog.open(FlowComponent, {
      disableClose: true,
      autoFocus: true,
      width: '60vw',
      data: {
        title: 'Edit Flow',
        id: id
      }
    });
    dialog.afterClosed().subscribe((result: any) => {
      if (result) {
        if (result.redirectTo) {
          this.router.navigate([result.redirectTo]);
          this.getFlows();
        }
      }
    });
  }

  showTags(tags, flowId, flow) {
    const dialog = this.dialog.open(TagsComponent, {
      disableClose: true,
      autoFocus: true,
      width: '60vw',
      data: {
        title: this.translate.instant('Add Tags'),
        tags: tags,
        flowId: flowId,
        flow: flow
      }
    });
    dialog.afterClosed().subscribe((result: any) => {
      if (result) {
        this.getFlows();
      }
    });
  }

  private search(searchText: string) {
    searchText = searchText.toLowerCase();
    this.flowTableDatasource = new MatTableDataSource<FlowTable>(this.allFlows.filter((i: any) => i.name.toLowerCase().indexOf(searchText) !== -1));
  }
}