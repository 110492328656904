<h1 mat-dialog-title>
  {{title}}
</h1>

<div mat-dialog-content>
  <p>{{message}}</p>
</div>

<div mat-dialog-actions align="end">
  <button mat-button class="secondary" (click)="onDismiss()">{{noButton}}</button>
  <button mat-raised-button class="primary" (click)="onConfirm()">{{yesButton}}</button>
  <button mat-button (click)="onCancel()" class="secondary" *ngIf="cancelButton">{{cancelButton}}</button>
</div>