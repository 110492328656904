import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from '../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { ThemePalette } from '@angular/material/core';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { ProgressBarMode } from '@angular/material/progress-bar';
import { LayoutUtilsService } from 'src/app/core/services/layout-utils.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { RequestService } from 'src/app/core/services/request.service';
import { CsvExporterService } from 'src/app/core/services/csvExporter.service';

interface ViewDialogData {
	dataType: string;
	title: string;
	modalSetting: any;
	confirmData: any;
	addName: any;
	path: string;
	role: string;
}

@Component({
	selector: 'app-uploader-dialog-modal',
	templateUrl: './custom-uploader-dialog.component.html',
	styleUrls: ['./custom-uploader-dialog.component.scss']
})
export class ModalUploaderDialogComponent implements OnInit {
	private subscriptions: Subscription[] = [];
	public selectedUser: any;
	public isAdmin: boolean = false;
	public isSuperAdmin: boolean = false;
	public errorMessage: string = '';
	public loading: boolean = false;
	public pictureLink: string = 'assets/images/profile.png';
	public fieldsDetails: any = undefined;
	public metaFieldTabsSetting = [];
	public tableDataTemp: any = undefined;

	public hasFormErrors: boolean = false;
	public allowedExtensions: string[] = ['csv'];
	public max_size: number = 10000000;

	public path: string = undefined;
	public addName: boolean = true;
	public showMoreInfo: boolean = false;
	public results: any = undefined;

	currentFiles: any[] = [];
	selectedFiles: FileList;
	progressInfos = [];
	progressInfosOld = [];
	fileInfos: Observable<any>;

	color: ThemePalette = 'primary';
	mode: ProgressBarMode = 'determinate';

	@ViewChild('fileCsvInput') fileCsvInput: ElementRef;
	constructor(
		public dialog: MatDialog, private translate: TranslateService,
		private requestService: RequestService, private loaderService: LoaderService,
		private layoutUtilsService: LayoutUtilsService,
		public dialogRef: MatDialogRef<ModalUploaderDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ViewDialogData, private csvService: CsvExporterService) {
		// console.log('UserViewDialogData', data);
		this.path = data.path;
		this.addName = data.addName;
	}

	ngOnInit() {
		this.subscriptions.push(
			this.requestService.currentUserSubject.subscribe((data) => {
				if (data) {
					this.selectedUser = data;
					this.isAdmin = this.requestService.isUserRoleAdmin();
					this.isSuperAdmin = this.requestService.isUserRoleSuperAdmin();
					this.buildSetting();
				}
			})
		);
	}
	private buildSetting() {
		if (!this.loading) {
			this.loading = true;
			this.errorMessage = '';
			let orgId = undefined;
			let lang = undefined;
			if (this.data.dataType === 'resource/user') {
				orgId = this.requestService.orgId;
				lang = this.requestService.lang;
			}
			this.requestService.getMetaData(this.data.dataType, undefined, (data, error) => {
				if (error) {
					this.errorMessage = error;
					this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
				}
				this.loading = false;
				if (data) {
					let newFields = data.results.fields.filter(i => i.name === '_id' || i.name === 'firstName' || i.name === 'lastName' || i.name === 'email');
					this.fieldsDetails = this.buildMetaSetting(data.results, parent = undefined);

					// let addon = '?';
					// if (this.data.dataType === 'resource/user') {
					// 	this.path = this.path + addon + 'resources' + '=' + environment.customKeys.roleView;
					// 	addon = '&';
					// }
					this.path = this.path + '?organizationId=' + this.requestService.orgId;

					let idx = 0;
					if (this.data.modalSetting.hasOwnProperty('customSettings')) {
						for (let fld of newFields) {
							if (this.data.modalSetting.customSettings.hasOwnProperty(fld.name)) {
								newFields[idx]['visible'] = this.data.modalSetting.customSettings[fld.name].visible;
								// this.path = this.path + addon + fld.name + '=' + this.data.modalSetting.customSettings[fld.name].value[0]._id;
								// addon = '&';
							}
							if (fld.name === 'privacyterm') {
								fld['nullable'] = false;
								fld['editable'] = true;
								fld['visible'] = true;
							}
							idx++;
						}
					}
					this.data.modalSetting.fields = newFields;
				} else {
					console.log(this.translate.instant('Something is Wrong'));
				}
			}, orgId, lang);
		}
	}
	convertArrayToObject(arr: any[]) {
		let obj = {};
		for (let a of arr) {
			obj[a._id] = a;
		}
		return obj;
	}
	buildMetaSetting(data, parent = undefined) {
		let dataObject = {};
		let tabObject = [];
		for (let col of data.fields) {
			if ((col.editable || !col.generated) && col.type !== 'object' && col.type !== 'table') {
				if (parent) {
					col['inputName'] = parent + col['name'];
				}
				dataObject[col.name] = col;
			} else if (col.type === 'object') {
				dataObject[col.name] = this.buildMetaSetting(col);
				tabObject.push({ name: col.name, displayName: col.displayName, fields: this.buildMetaSetting(col, col.name) });
			} else if (col.type === 'table') {
				dataObject[col.name] = this.buildMetaSetting(col);
			}
		}
		this.metaFieldTabsSetting = tabObject;
		return dataObject;
	}
	public downloadSample() {
		const items: any[] = [];
		let csvLine: any = {
		}
		for (let col of this.data.modalSetting.fields) {
			if ((col.editable || !col.generated) && col.creatable && (col.visible || (col.admin && this.isAdmin))) {
				if (col.type == 'boolean') {
					csvLine[col.name] = 'true|false';
				} else if (col.type == 'datetime' || col.type == 'date') {
					csvLine[col.name] = moment.utc().format('YYYY-MM-DDTHH:mm');
				} else if (col.type == 'email') {
					csvLine[col.name] = 'user@stellar.online';
				} else if (col.type == 'image') {
					// don't insert
				} else if (col.type == 'enum') {
					let listOption = '';
					let idx = 1;
					for (let itm of col.enum) {
						listOption = listOption + itm.value;
						if (idx !== col.enum.length) {
							listOption = listOption + '|';
						}
						idx++;
					}
					csvLine[col.name] = listOption;
				} else if (col.type == 'reference' && this.data.dataType === 'resource/user' && col.name === 'resources') {
					// csvLine[col.name] = 'admin|viewer|editor';
				} else {
					csvLine[col.name] = '';
				}
			}
		}
		items.push(csvLine);
		let date = new Date();
		let formattedDate = moment(date).format('YYYYMMDDHHmm');
		this.csvService.exportToCsv(formattedDate + '-user-sample.csv', items);
	}

	closeModal(): void {
		let uploadedProcesses = this.progressInfosOld.filter((itm) => itm.uploaded);
		if (uploadedProcesses.length > 0) {
			this.dialogRef.close(uploadedProcesses);
		} else {
			this.dialogRef.close();
		}
	}
	selectFiles(target) {
		this.progressInfos = [];
		this.currentFiles = [];
		this.selectedFiles = target.files;
		this.readFiles(this.selectedFiles, (currentFile) => {
			this.currentFiles.push(currentFile);
			// this.uploadFiles();
		});
	}
	deleteFile(i) {
		this.currentFiles.splice(i, 1);
	}
	cancelFile(idx) {
		if (this.progressInfos[idx]['subscription']) {
			this.progressInfos[idx]['subscription'].unsubscribe();
		}
		this.progressInfos[idx].error = true;
		this.progressInfos[idx].complete = true;
		this.progressInfos[idx].value = 0;
		this.progressInfos[idx].message = 'Cancelled';
		this.updateLoadingStatus();
	}
	onBrowseFiles(target: any): void {
		if (target.files.length > 0) {
			this.results = undefined;
			this.selectFiles(target);
		}
	}
	uploadFiles() {
		this.errorMessage = '';
		this.loading = true;
		this.loaderService.display(true);
		for (let i = 0; i < this.currentFiles.length; i++) {
			this.upload(i, this.currentFiles[i]);
		}
	}
	uploadFile(idx) {
		this.errorMessage = '';
		this.loading = true;
		this.loaderService.display(true);
		this.upload(idx, this.currentFiles[idx]);
	}
	updateLoadingStatus() {
		let remainingProcesses = this.progressInfos.filter((itm) => !itm.complete && !itm.error);
		if (remainingProcesses.length === 0) {
			this.loading = false;
			this.loaderService.display(false);
			this.progressInfosOld = this.progressInfos;
		}
	}
	upload(idx, browsed_file) {
		this.progressInfos[idx] = browsed_file;
		this.progressInfos[idx]['value'] = 0;
		this.progressInfos[idx]['uploaded'] = undefined;
		this.progressInfos[idx]['complete'] = false;
		this.progressInfos[idx]['subscription'] = undefined;
		this.progressInfos[idx]['message'] = undefined;
		// if (browsed_file.message && browsed_file.error) {
		// 	this.progressInfos[idx]['error'] = true;
		// 	this.progressInfos[idx]['complete'] = true;
		// } else {
		this.progressInfos[idx]['subscription'] = this.requestService.onUploadFilesByPathNew(this.path, browsed_file, undefined, undefined, undefined, undefined, this.addName).subscribe(
			event => {
				if (event.type === HttpEventType.UploadProgress) {
					this.progressInfos[idx].value = Math.round(100 * event.loaded / event.total);
				} else if (event instanceof HttpResponse) {
					this.progressInfos[idx].complete = true;
					this.progressInfos[idx].message = 'Completed';
					if (event.body && !event.body.status && event.body.message) {
						this.progressInfos[idx].error = true;
						this.progressInfos[idx].message = 'Could not upload the file';
					}
					if (event.body && event.body.status) {
						// console.log('event.body', event.body);
						this.results = event.body;
						this.progressInfos[idx]['uploaded'] = event.body.results;
						this.progressInfos[idx]['hasPrivacyTerm'] = false;
						this.progressInfos[idx]['hasPrivacyTerm'] = this.analyzeHasPrivacyTermData(event.body.results);
					}
					this.updateLoadingStatus();
				}
			},
			err => {
				this.progressInfos[idx].error = true;
				this.progressInfos[idx].complete = true;
				this.progressInfos[idx].value = 0;
				this.progressInfos[idx]['hasPrivacyTerm'] = false;
				this.progressInfos[idx].message = 'Could not upload the file';
				this.progressInfos[idx]['subscription'].unsubscribe;
				this.updateLoadingStatus();
			});
		// }
	}
	analyzeHasPrivacyTermData(results = []) {
		let hasPrivacyTerm = results.filter((itm) => itm.type && itm.type === "privacyterm");
		if (hasPrivacyTerm.length > 0) {
			return true;
		}
		return false;
	}
	/**
	 *  @param files: list of browsed files
	 *  @param index: iterator over browsed images
	 *
	 *  read files browsed by user
	 */
	readFiles(files, callback, index = 0): void {
		// for (let index = 0; index < files.length; index++) {
		if (index in files) {
			let currentFile = { error: false, name: files[index].name, text: files[index].name.split('.')[0], id: files[index].id, originalFile: files[index], extension: 'csv', source_url: null, message: 'The file structure is correct, please click Confirm', messages: [] };
			let fileExt = files[index].name.split('.').pop();
			currentFile.extension = fileExt.toLowerCase();
			// this.imageExtension = fileExt.toLowerCase();
			const max_size = this.max_size;
			if (files[index].size > max_size) {
				currentFile.error = true;
				currentFile.message = this.translate.instant('Maximum size allowed is') + ' ' + max_size / 1000000 + 'MB';
				callback(currentFile);
			} else if (this.allowedExtensions.indexOf(currentFile.extension) === -1) {
				currentFile.error = true;
				currentFile.message = this.translate.instant('The file type is not allowed');
				callback(currentFile);
			} else {
				this.readFileAsText(files[index], (event) => {
					let allTextLines = event.split(/\r|\n|\r/);
					if (allTextLines.length > 0) {
						let headers = this.getHeaders(allTextLines[0]);
						if (headers.length > 0) {
							let messages = [];
							for (let col of headers) {
								if (!this.fieldsDetails.hasOwnProperty(col)) {
									messages.push(col + ' header is not a valid field');
								}
							}
							for (let col of this.data.modalSetting.fields) {
								if (!col.nullable && (col.editable || !col.generated) && col.creatable && (col.visible || (col.admin && this.isAdmin))) {
									if ((this.data.dataType === 'resource/user' && col.name === 'resources') || col.type == 'image' || (col.type == 'boolean' && col.name !== 'privacyterm')) {
										// do nothing
									} else {
										if (!headers.includes(col.name)) {
											messages.push(col.name + ' header is required in the csv file');
										}
									}
								}
							}
							if (messages.length > 0) {
								currentFile.error = true;
								currentFile.message = this.translate.instant('The file structure is incorrect');
								currentFile.messages = messages;
							}
						} else {
							currentFile.error = true;
							currentFile.message = this.translate.instant('The file structure is incorrect');
						}
					} else {
						currentFile.error = true;
						currentFile.message = this.translate.instant('The file structure is incorrect');
					}
					callback(currentFile);
				});
			}
		}
	}
	getHeaders(headers) {
		let headerList = headers.split(';');
		if (headerList.length > 0 && headers.indexOf(',') == -1) {
			return headerList;
		}
		headerList = headers.split(',');
		return headerList;
	}
	readFileAsText(file, callback): void {
		let reader = new FileReader();
		reader.onload = () => {
			callback(reader.result);
		}
		reader.readAsText(file);
	}
	readFile(file, callback): void {
		let reader = new FileReader();
		reader.onload = () => {
			callback(reader.result);
		}
		reader.readAsDataURL(file);
	}
	readImage(file, image, callback): void {
		image.onload = () => {
			callback(image);
		}
		image.src = file;
	}
	dataURLtoFile(dataurl, filename) {
		var arr = dataurl.split(','),
			mime = arr[0].match(/:(.*?);/)[1],
			bstr = atob(arr[1]),
			n = bstr.length,
			u8arr = new Uint8Array(n);

		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}

		return new File([u8arr], filename, { type: mime });
	}

}
