import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { LayoutUtilsService } from "src/app/core/services/layout-utils.service";
import { LoaderService } from "src/app/core/services/loader.service";
import { RequestService } from "src/app/core/services/request.service";
import { ConfirmInputEntityDialogComponent } from "src/app/shared/modals/confirm-input-entity-dialog/confirm-input-entity-dialog.component";
import { map, Observable } from 'rxjs';
import { FormControl, Validators } from "@angular/forms";
import * as XLSX from 'xlsx';
import { ConfirmFileEntityDialogComponent } from "src/app/shared/modals/confirm-file-entity-dialog/confirm-file-entity-dialog.component";
import { DiagramService } from "src/app/core/services/diagram.service";
import { AlertEntityDialogComponent } from "src/app/shared/modals";

declare var BpmnJS: any;

export interface Tag {
    name: string;
}

@Component({
    selector: 'app-diagram',
    templateUrl: './diagram.component.html',
    styleUrls: ['./diagram.component.scss']
})

export class DiagramComponent implements OnInit, OnDestroy {
    currentUser: any;
    json: any;
    flowName: string = '';
    bpmnModeler: any = undefined;
    xml: any = '';
    diagramUrl: string = '/assets/bpmn/diagram.bpmn';
    currentNode: any = {};
    maleContentFormControl = new FormControl('');
    femaleContentFormControl = new FormControl('');
    otherContentFormControl = new FormControl('');
    scoreFormControl = new FormControl('');
    audioFileDisplay = new FormControl('');
    videoFileDisplay = new FormControl('');
    videoUrlDisplay = new FormControl('', [Validators.pattern(/^(ftp|http|https):\/\/[^ "]+$/)]);
    backgroundFileDisplay = new FormControl('');
    audioFileId = new FormControl('');
    videoFileId = new FormControl('');
    videoUrlId = new FormControl('');
    backgroundFileId = new FormControl('');
    tagformControl = new FormControl('');
    feedbackControl = new FormControl('');
    toJsonTemplate: any = {};
    flow: any = {};
    loading: boolean = false;
    audioFile: any;
    backgroundFile: any;
    videoFile: any;
    allowedImageExtensions: string[] = ['jpeg', 'jpg', 'png'];
    allowedAudioExtensions: string[] = ['wav', 'mp3'];
    allowedVideoExtensions: string[] = ['mp4', 'mpeg'];
    acceptedImageExtensions: string = 'image/jpeg,image/jpg,image/png';
    acceptedAudioExtensions: string = 'audio/wav,audio/mp3';
    acceptedVideoExtensions: string = 'video/mp4,video/mpeg';
    validatingStatus: number = 0;
    validationErrors: string[] = [];
    showErrors: boolean = false;
    diagramChanged: boolean = false;
    tags: Tag[] = [];
    tagsTitle: string = 'Tags';
    flowRecord: any;
    videoType: string = '';

    private subscriptions: any[] = <any>[];
    private flowId: string = '';
    private errorTimeout: number = 2000;
    private errorDialogTimeout: any = undefined;
    private tempFormFields: any = {};
    private processedNodes: any = [];

    @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
    @ViewChild('videoFileInput') videoFileInput: ElementRef<HTMLInputElement>;
    @ViewChild('videoUrlInput') videoUrlInput: ElementRef<HTMLInputElement>;

    constructor(private titleService: Title, private requestService: RequestService, private dialog: MatDialog, private activatedRoute: ActivatedRoute, private translate: TranslateService, private layoutUtilsService: LayoutUtilsService, private loadService: LoaderService, private diagramService: DiagramService, private router: Router, private changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.titleService.setTitle('Flow Engine - BPMN');
        this.subscriptions.push(this.requestService.currentUserSubject.subscribe((value) => {
            if (value) {
                this.currentUser = value;
            }
        }));
        this.subscriptions.push(this.activatedRoute.params.subscribe((params: any) => {
            if (params.hasOwnProperty('id') && params.hasOwnProperty('id')) {
                this.flowId = params['id'];
                this.getFlow(this.flowId, () => this.initDiagram());
            }
            else {
                this.initDiagram();
            }
        }));

        this.subscriptions.push(this.diagramService.diagramChangedSubject.subscribe((value: any) => {
            if (value)
                this.canExit(this.translate.instant('This diagram has unsaved changes. Do you want to save before leaving?'), value.url);
        }));

        this.subscriptions.push(this.diagramService.overwriteDiagramChanged.subscribe((value: any) => {
            this.diagramChanged = value;
        }));

        // this.initDiagram();
    }

    ngOnDestroy() {
        try {
            this.bpmnModeler.destroy();
        }
        catch (e) { }
        this.validationErrors = [];
        this.subscriptions.forEach(el => el.unsubscribe());
        this.validatingStatus = 0;
        this.diagramService.diagramChangedSubject.next(undefined);
    }

    getFlow(id: string, callback: any) {
        this.loadService.display(true);
        this.requestService.getRecord(id, 'flow', (data: any, error: any) => {
            // console.log(data, error)
            this.flow = data.results.flow;
            this.flowRecord = data.results;
            this.flowName = data.results.name;

            if (data.results.tags) {
                this.tagsTitle = Object.keys(data.results.tags)[0];
                this.tags = <Tag[]>Object.values(data.results.tags)[0] || [];
            }

            this.diagramUrl = data.results.fileLink || this.diagramUrl;
            this.loadService.display(false);

            callback();
        });
    }

    async exportDiagram() {
        try {
            this.xml = await this.bpmnModeler.saveXML({ format: true });
            // console.log('xml', this.xml)
        } catch (err) {
            console.error('could not save BPMN 2.0 diagram', err);
        }
    }

    async convertToJsonTemplate(doSave: boolean = true, callback?: any) {
        // this.loadService.display(true);
        // this.toJsonTemplate = { nodes: [], connections: [], selectedOptions: [] };
        await this.exportDiagram();
        this.loadService.display(true);
        this.requestService.saveRecord({
            flow: this.flow,
            xmlstring: JSON.stringify(this.xml)
        }, 'user/converttojson', async (jsonObj, error) => {
            if (jsonObj) {
                this.toJsonTemplate = jsonObj.results;
                if (this.flowId && doSave) {
                    await this.validate();
                    if (this.validatingStatus === 2) {
                        await this.updateFlow(false, true).then(() => {
                            this.diagramChanged = false;
                            this.diagramService.diagramChangedLock.next(this.diagramChanged);
                            if (callback)
                                callback();
                        });
                    }
                    else {
                        this.layoutUtilsService.showNotification(this.translate.instant('Diagram includes errors. Could not save.'), this.translate.instant('Dismiss'));
                    }
                }
            }
            else if (error) {
                this.layoutUtilsService.showNotification(this.translate.instant('Diagram includes errors. Could not save.'), this.translate.instant('Dismiss'));
            }
            this.loadService.display(false);
        });

        // const parser = new DOMParser();
        // let xml = parser.parseFromString(this.xml.xml, 'text/xml');



        // if (xml) {
        //     let startNode = xml.getElementsByTagName('bpmn:startEvent');
        //     if (startNode && startNode.length) {
        //         this.toJsonTemplate.nodes.push({
        //             id: startNode[0].id,
        //             name: 'start node',
        //             type: 'start',
        //             metadata: []
        //         });
        //     }

        //     let endNode = xml.getElementsByTagName('bpmn:endEvent');
        //     if (endNode && endNode.length) {
        //         this.toJsonTemplate.nodes.push({
        //             id: endNode[0].id,
        //             name: 'end node',
        //             type: 'end',
        //             metadata: []
        //         });
        //     }

        //     let sequenceFlows: any = xml.getElementsByTagName('bpmn:sequenceFlow');
        //     // console.log('sequenceFlows', sequenceFlows)
        //     if (sequenceFlows.length) {
        //         for (let sequence of sequenceFlows) {
        //             this.toJsonTemplate.connections.push({
        //                 id: sequence.id,
        //                 source: sequence.attributes.sourceRef.nodeValue,
        //                 target: sequence.attributes.targetRef.nodeValue
        //             });
        //         }
        //     }

        //     let conditions: any = xml.getElementsByTagName('bpmn:exclusiveGateway');
        //     if (conditions.length) {
        //         // console.log('conditions', conditions)
        //         for (let condition of conditions) {
        //             let metadata: any = { fields: [], media: [] };
        //             let conds: any = [];
        //             if (condition.getElementsByTagName('bpmn:outgoing')) {
        //                 let conditionName = {
        //                     male: condition.getAttribute('name'),
        //                     female: condition.getAttribute('name'),
        //                     other: condition.getAttribute('name')
        //                 };
        //                 let media: any = [];

        //                 for (let outgoing of condition.getElementsByTagName('bpmn:outgoing')) {
        //                     let sequence: any = xml.getElementById(outgoing.innerHTML);
        //                     // console.log('sequence', sequence, sequence.getAttribute('targetRef'))
        //                     if (sequence) {
        //                         let option: any = xml.getElementById(sequence.getAttribute('targetRef'));
        //                         let name = option.getAttribute('name');
        //                         let score = '0';

        //                         if (option && name && name.match(/\[([^)]+)\]/)) {
        //                             score = name.match(/\[([^)]+)\]/)[1];
        //                             name = name.replaceAll('[' + score + ']', '');
        //                         }
        //                         // console.log('wfwe', name, score)
        //                         // get from flow in db
        //                         let text: any = {
        //                             male: name,
        //                             female: name,
        //                             other: name
        //                         };

        //                         let tags = "";
        //                         let feedback = "";

        //                         if (this.flow && Object.keys(this.flow).length) {
        //                             // console.log('flowww', this.flow, this.flow.nodes.filter((i: any) => i.id === condition.id), this.flow.nodes.filter((i: any) => i.id === condition.id).find((j: any) => j.fieldId === option.id))
        //                             let findState = this.flow.nodes.find((i: any) => i.id === condition.id);
        //                             if (findState) {
        //                                 let findField = findState.metadata.fields.find((j: any) => j.fieldId === option.id);
        //                                 if (findField) {
        //                                     if (Number(findField.fieldScore))
        //                                         score = findField.fieldScore;
        //                                     else
        //                                         score = score;
        //                                     score = score;
        //                                     text = {
        //                                         male: findField.fieldValue.male,
        //                                         female: findField.fieldValue.female,
        //                                         other: findField.fieldValue.other
        //                                     };
        //                                     tags = findField.fieldTags;
        //                                     feedback = findField.feedback;
        //                                 }
        //                                 conditionName = {
        //                                     male: findState.name.male,
        //                                     female: findState.name.female,
        //                                     other: findState.name.other
        //                                 };

        //                                 media = findState.metadata.media || [];
        //                             }
        //                         }

        //                         metadata.media = media;

        //                         metadata.fields.push({
        //                             fieldId: option.id,
        //                             type: 'formField',
        //                             fieldType: 'radio',
        //                             fieldValue: text,
        //                             fieldScore: score,
        //                             fieldTags: tags,
        //                             feedback: feedback
        //                         });
        //                         // console.log('wfwef', this.toJsonTemplate, sequence.getAttribute('targetRef'), this.toJsonTemplate.connections.find((i: any) => i.source === sequence.getAttribute('targetRef')))
        //                         conds.push({
        //                             type: 'selection',
        //                             fieldId: option.id,
        //                             connectionId: this.toJsonTemplate.connections.find((i: any) => i.target === sequence.getAttribute('targetRef')).id
        //                         });
        //                     }
        //                 }
        //                 // console.log('metadata', metadata)
        //                 this.toJsonTemplate.nodes.push({
        //                     id: condition.id,
        //                     name: conditionName,
        //                     type: 'condition',
        //                     metadata: metadata,
        //                     conditions: conds
        //                 });
        //             }
        //         }
        //     }

        //     let tasks: any = xml.getElementsByTagName('bpmn:task');
        //     if (tasks.length) {
        //         let allConditions = this.toJsonTemplate.nodes.filter((i: any) => i.type === 'condition');
        //         // console.log('tasks', tasks)
        //         for (let task of tasks) {
        //             let text: any = {
        //                 male: task.getAttribute('name'),
        //                 female: task.getAttribute('name'),
        //                 other: task.getAttribute('name')
        //             };

        //             let metadata: any = { fields: [], media: [] };

        //             if (this.flow && Object.keys(this.flow).length) {
        //                 let findState = this.flow.nodes.find((i: any) => i.id === task.id);
        //                 if (findState) {
        //                     text = {
        //                         male: findState.name.male,
        //                         female: findState.name.female,
        //                         other: findState.name.other
        //                     };

        //                     metadata = findState.metadata;
        //                 }
        //             }
        //             // console.log('xs', this.toJsonTemplate.nodes.filter((i: any) => i.type === 'condition'), this.toJsonTemplate.nodes.filter((i: any) => i.type === 'condition').find((j: any) => j.fieldId === task.id))

        //             if (allConditions.find((i: any) => i.metadata.fields.find((j: any) => j.fieldId === task.id)))
        //                 this.toJsonTemplate.nodes.push({
        //                     id: task.id,
        //                     name: text,
        //                     type: 'option',
        //                 });
        //             else
        //                 this.toJsonTemplate.nodes.push({
        //                     id: task.id,
        //                     name: text,
        //                     type: 'state',
        //                     metadata: metadata,
        //                 });
        //         }
        //     }

        //     if (this.flowId && doSave) {
        //         await this.validate();
        //         if (this.validatingStatus === 2) {
        //             this.updateFlow(false, true);
        //             this.diagramChanged = false;
        //         }
        //         else {
        //             this.layoutUtilsService.showNotification(this.translate.instant('Diagram includes errors. Could not save.'), this.translate.instant('Dismiss'));
        //         }
        //     }
        // }
    }

    private async updateFlow(dynamicLoader: boolean = false, showNotification: boolean = false) {
        if (!dynamicLoader)
            this.loading = true;
        else
            this.showSmallLargeLoader(false, true);

        this.requestService.updateRecord({
            flow: this.toJsonTemplate
        }, 'flow', this.flowId, async (data: any, error: any) => {
            if (data) {
                this.diagramChanged = false;
                this.diagramService.diagramChangedLock.next(this.diagramChanged);
                if (showNotification)
                    this.layoutUtilsService.showNotification(this.translate.instant('Updated Successfully'), this.translate.instant('Dismiss'));
                await this.uploadFile(dynamicLoader);
            }
            if (error) {
                if (showNotification)
                    this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
                if (!dynamicLoader)
                    this.loading = false;
                else
                    this.showSmallLargeLoader(false, false);
            }
        });
    }

    private async uploadFile(dynamicLoader: boolean = false) {
        let result = await this.bpmnModeler.saveXML({ format: true });
        const { xml } = result;

        let xmlBlob = new Blob([xml], {
            type: 'text/xml'
        });

        // let file = new File([xmlBlob], 'ddd.bpmn');
        // console.log('file', file, file.name)
        // let fileName = Math.random().toString().substring(7) + '.bpmn';

        // let downloadLink = document.createElement('a');
        // downloadLink.download = fileName;
        // downloadLink.innerHTML = 'Get BPMN File';
        // downloadLink.href = window.URL.createObjectURL(xmlBlob);
        // downloadLink.onclick = function (event) {
        //     // document.body.removeChild(event.target);
        // };
        // downloadLink.style.visibility = 'hidden';
        // document.body.appendChild(downloadLink);
        // downloadLink.click();

        // console.log(xmlBlob, file)
        this.requestService.uploadFile(this.flowId, this.flowId + '.bpmn', xmlBlob, 'file', 'flow/file/upload/', '')
            .subscribe(
                (results: any) => {
                    if (!dynamicLoader)
                        this.loading = false;
                    else
                        this.showSmallLargeLoader(false, false);
                    // console.log('currentFile', currentFile);
                    // if (results['status']) {
                    //     this.layoutUtilsService.showNotification(this.translate.instant('File uploaded successfully'), this.translate.instant('Dismiss'));
                    // } else {
                    //     this.layoutUtilsService.showNotification('Error:' + results['message'], 'Dismiss');
                    // }
                },
                (error: any) => {
                    if (!dynamicLoader)
                        this.loading = false;
                    else
                        this.showSmallLargeLoader(false, false);
                    // this.layoutUtilsService.showNotification(this.translate.instant('Error:') + ' ' + this.translate.instant('Error uploading file'), this.translate.instant('Dismiss'));
                }
            );
    }

    importFile() {
        const dialogRef = this.dialog.open(ConfirmFileEntityDialogComponent, {
            data: {
                title: this.translate.instant('Select BPMN File'),
                type: 'string',
                confirmbtn: this.translate.instant('Submit'),
                cancelbtn: this.translate.instant('Cancel')
            },
            width: '40%',
            disableClose: false
        });
        dialogRef.afterClosed().subscribe((result: any) => {
            // console.log('url uis', result)
            if (result) {
                if (result?.file && result.file.name.match('(.bpmn)$')?.length) {
                    let reader = new FileReader();
                    reader.readAsText(result.file);
                    reader.onloadend = async () => {
                        this.validationErrors = [];
                        this.validatingStatus = 0;
                        // console.log('read', reader.result);
                        await this.bpmnModeler.importXML(reader.result);
                        // let canvas = this.bpmnModeler.get('canvas');
                        this.centerDiagram();
                        this.validate();
                    };
                }
                else {
                    this.layoutUtilsService.showNotification(this.translate.instant('URL should end with .bpmn'), this.translate.instant('Dismiss'));
                }
            }
        });
    }

    importDiagram() {
        //https://cdn.staticaly.com/gh/bpmn-io/bpmn-js-examples/dfceecba/starter/diagram.bpmn
        const dialogRef = this.dialog.open(ConfirmInputEntityDialogComponent, {
            data: {
                // description: 'URL',
                title: this.translate.instant('Import BPMN URL'),
                type: 'string',
                confirmbtn: this.translate.instant('Submit'),
                cancelbtn: this.translate.instant('Cancel')
            },
            width: '40%',
            disableClose: false
        });
        dialogRef.afterClosed().subscribe((result: any) => {
            // console.log('url uis', result)
            if (result) {
                if (result.length && result.match('^(http(s)?:\/\/|www\.).*(\.bpmn)$')?.length) {
                    this.diagramUrl = result;
                    this.loadDiagram();
                    this.validationErrors = [];
                    this.validatingStatus = 0;
                }
                else {
                    this.layoutUtilsService.showNotification(this.translate.instant('URL should end with .bpmn'), this.translate.instant('Dismiss'));
                }
            }
            else {
                // this.diagramUrl = '/assets/bpmn/diagram.bpmn';
            }
        });
    }

    async saveFile() {
        try {
            await this.bpmnModeler.saveXML().then((xml: any) => {
                // console.log(xml)
                let svgBlob = new Blob([xml.xml], {
                    type: 'text/xml'
                });

                // let fileName = Math.random().toString().substring(7) + '.bpmn';
                let fileName = this.flowRecord.name + '_' + this.flowRecord.versionId + '.bpmn';

                let downloadLink = document.createElement('a');
                downloadLink.download = fileName;
                downloadLink.innerHTML = 'Get BPMN SVG';
                downloadLink.href = window.URL.createObjectURL(svgBlob);
                downloadLink.onclick = function (event) {
                    // document.body.removeChild(event.target);
                };
                downloadLink.style.visibility = 'hidden';
                document.body.appendChild(downloadLink);
                downloadLink.click();
            });
        } catch (err) {
            console.error('could not save BPMN 2.0 diagram', err);
        }
    }

    async saveSVG() {
        try {
            let result = await this.bpmnModeler.saveSVG({ format: true });
            const { svg } = result;

            let svgBlob = new Blob([svg], {
                type: 'image/svg+xml'
            });

            let fileName = this.flowRecord.name + '_' + this.flowRecord.versionId + '.svg';

            let downloadLink = document.createElement('a');
            downloadLink.download = fileName;
            downloadLink.innerHTML = 'Get BPMN SVG';
            downloadLink.href = window.URL.createObjectURL(svgBlob);
            downloadLink.onclick = function (event) {
                // document.body.removeChild(event.target);
            };
            downloadLink.style.visibility = 'hidden';
            document.body.appendChild(downloadLink);
            downloadLink.click();

        } catch (err) {
            console.error('could not save BPMN 2.0 diagram', err);
        }
    }

    initDiagram() {
        try {
            // modeler instance
            this.bpmnModeler = new BpmnJS({
                container: '#bpmn-tool',
                keyboard: {
                    bindTo: window
                }
            });

            this.loadDiagram();

            let eventBus = this.bpmnModeler.get('eventBus');

            // eventBus.on('drag.end', (e: any) => {
            //     this.validationErrors = [];
            //     this.validatingStatus = 0;
            //     // console.log(e)
            //     // if (e.shape.type === 'bpmn:StartEvent')
            //     //     (document.getElementsByClassName('bpmn-icon-start-event-none')[0] as any).style.display = 'none';
            // })

            // eventBus.on('elements.changed', (e: any) => {
            //     // console.log(e)
            //     this.validationErrors = [];
            //     this.validatingStatus = 0;
            // });

            // eventBus.on('connection.add', (e: any) => {
            //     console.log(e)
            //     this.validationErrors = [];
            //     this.validatingStatus = 0;
            // })

            // eventBus.on('shape.remove', (e: any) => {
            //     this.validationErrors = [];
            //     this.validatingStatus = 0;
            //     // console.log('deleted', e)
            //     // if (e.element.type === 'bpmn:StartEvent')
            //     //     (document.getElementsByClassName('bpmn-icon-start-event-none')[0] as any).style.display = '';
            // })

            eventBus.on('connection.added', (e: any) => {

            });

            eventBus.on('contextPad.open', (e: any) => {
                if (document.querySelector('[data-action*="append.end-event"]')) {
                    let element: any = document.querySelector('[data-action*="append.end-event"]');
                    element.title = 'Append End Event';
                }
                if (document.querySelector('[data-action*="append.gateway"]')) {
                    let element: any = document.querySelector('[data-action*="append.gateway"]');
                    element.title = 'Append Decision/Question';
                    let group = document.querySelector('[data-group*="edit"]');
                    if (group)
                        group.prepend(element);
                }
                if (document.querySelector('[data-action*="append.append-task"]')) {
                    let element: any = document.querySelector('[data-action*="append.append-task"]');
                    element.title = 'Append Task';
                    let group = document.querySelector('[data-group*="model"]');
                    if (group)
                        group.append(element);
                }
                if (document.querySelector('[data-action*="append.text-annotation"]')) {
                    let element: any = document.querySelector('[data-action*="append.text-annotation"]');
                    element.title = 'Add Text Annotation';
                    let group = document.querySelector('[data-group*="edit"]');
                    if (group)
                        group.append(element);
                }
                if (document.querySelector('[data-action*="delete"]')) {
                    let element: any = document.querySelector('[data-action*="delete"]');
                    element.title = 'Delete Shape';
                    let group = document.querySelector('[data-group*="connect"]');
                    if (group)
                        group.append(element);
                }
                if (document.querySelector('[data-action*="connect"]')) {
                    let element: any = document.querySelector('[data-action*="connect"]');
                    element.title = 'Append Connector';
                    let group = document.querySelector('[data-group*="connect"]');
                    if (group)
                        group.prepend(element);
                }
            });

            eventBus.on('element.click', (e: any) => {
                // this.closeSidePanel();
                // if (document.querySelector('[data-action*="append.end-event"]')) {
                //     let element: any = document.querySelector('[data-action*="append.end-event"]');
                //     element.title = 'Append End Event';
                // }
                // if (document.querySelector('[data-action*="append.gateway"]')) {
                //     let element: any = document.querySelector('[data-action*="append.gateway"]');
                //     element.title = 'Append Decision/Question';
                //     document.querySelector('[data-group*="edit"]')!.prepend(element);
                // }
                // if (document.querySelector('[data-action*="append.append-task"]')) {
                //     let element: any = document.querySelector('[data-action*="append.append-task"]');
                //     element.title = 'Append Task';
                //     document.querySelector('[data-group*="model"]')!.append(element);
                // }
                // if (document.querySelector('[data-action*="append.text-annotation"]')) {
                //     let element: any = document.querySelector('[data-action*="append.text-annotation"]');
                //     element.title = 'Add Text Annotation';
                //     document.querySelector('[data-group*="edit"]')!.append(element);
                // }
                // if (document.querySelector('[data-action*="delete"]')) {
                //     let element: any = document.querySelector('[data-action*="delete"]');
                //     element.title = 'Delete Shape';
                //     document.querySelector('[data-group*="connect"]')!.append(element);
                // }
                // if (document.querySelector('[data-action*="connect"]')) {
                //     let element: any = document.querySelector('[data-action*="connect"]');
                //     element.title = 'Append Connector';
                //     document.querySelector('[data-group*="connect"]')!.prepend(element);
                // }
            });

            eventBus.on('element.changed', (e: any) => {
                this.diagramChanged = true;
                this.diagramService.diagramChangedLock.next(this.diagramChanged);
            });

            eventBus.on('element.dblclick', async (e: any) => {
                let canvas: any = this.bpmnModeler.get('canvas');
                if (e.element.type === 'bpmn:Task' || e.element.type === 'bpmn:EndEvent' || e.element.type === 'bpmn:startEvent' || e.element.type === 'bpmn:ExclusiveGateway') {
                    // console.log(e, 'on', e.element.id, this.bpmnModeler.get('overlays'), this.bpmnModeler.get('canvas'));

                    if (e.element.type === 'bpmn:Task' || e.element.type === 'bpmn:ExclusiveGateway') {
                        let isConditionNode = false;
                        e.element.incoming.forEach((sequence: any) => {
                            // console.log(canvas._rootElement.children, canvas._rootElement.children.filter((i: any) => i.type === 'bpmn:ExclusiveGateway'))
                            canvas._rootElement.children.filter((i: any) => i.type === 'bpmn:ExclusiveGateway').forEach((j: any) => {
                                if (!isConditionNode && j.outgoing.find((k: any) => k.type === 'bpmn:SequenceFlow' && k.id === sequence.id)) {
                                    isConditionNode = true;
                                }
                            });
                        });

                        await this.closeSidePanel(false).then(() => {
                            this.showSidePanel(e.element.type, e.element.id, isConditionNode, e.element.businessObject.name);
                        });
                        // console.log('isConditionNode', isConditionNode)                    
                    }
                }
            });

            this.bpmnModeler.on('import.done', (event: any) => {
                if (document.querySelector('[data-action*="create.exclusive-gateway"]')) {
                    let element: any = document.querySelector('[data-action*="create.exclusive-gateway"]');
                    element.title = 'Add Decision/Question';
                }
                if (document.querySelector('[data-action*="create.task"]')) {
                    let element: any = document.querySelector('[data-action*="create.task"]');
                    element.title = 'Add Task';
                }
                if (document.querySelector('[data-action*="lasso-tool"]')) {
                    let element: any = document.querySelector('[data-action*="lasso-tool"]');
                    element.title = 'Select Tool';
                }
                if (document.querySelector('[data-action*="hand-tool"]')) {
                    let element: any = document.querySelector('[data-action*="hand-tool"]');
                    element.title = 'Hand Tool';
                }
                if (document.querySelector('[data-action*="space-tool"]')) {
                    let element: any = document.querySelector('[data-action*="space-tool"]');
                    element.title = 'Add/Remove space between tasks';
                }
                if (document.querySelector('[data-action*="global-connect-tool"]')) {
                    let element: any = document.querySelector('[data-action*="global-connect-tool"]');
                    element.title = 'Add Connector';
                }
                if (document.querySelector('[data-action*="create.start-event"]')) {
                    let element: any = document.querySelector('[data-action*="create.start-event"]');
                    element.title = 'Start Event';
                }
                if (document.querySelector('[data-action*="create.end-event"]')) {
                    let element: any = document.querySelector('[data-action*="create.end-event"]');
                    element.title = 'End Event';
                }
                setTimeout(() => this.resetZoom(), 100);
            });
        }
        catch (e) { }
    }

    private async showSidePanel(type: string, id: string, isConditionNode: boolean, stateTitle: string) {
        await this.convertToJsonTemplate(false);
        await this.setFormVariables(type, id, isConditionNode, stateTitle);
    }

    setFormVariables(type: string, id: string, isConditionNode: boolean, stateTitle: string) {
        this.currentNode.id = id;
        this.currentNode.isCondition = isConditionNode;
        this.currentNode.stateTitle = stateTitle;

        if (type === 'bpmn:ExclusiveGateway') {
            let gateway = this.toJsonTemplate.nodes.find((i: any) => i.type === 'condition' && i.id === id);
            this.currentNode.type = 'Gateway';
            this.currentNode.stateTitle = 'Question';
            this.maleContentFormControl.setValue(gateway.name.male);
            this.femaleContentFormControl.setValue(gateway.name.female);
            this.otherContentFormControl.setValue(gateway.name.other);

            if (gateway.metadata.media.length === 0)
                gateway.metadata.media.push({ audio: {}, video: {}, background: {} });

            this.backgroundFileDisplay.setValue(gateway.metadata.media[0].background.url);
            this.backgroundFileId.setValue(gateway.metadata.media[0].background._id);
            this.audioFileDisplay.setValue(gateway.metadata.media[0].audio.url);
            this.audioFileId.setValue(gateway.metadata.media[0].audio._id);
            this.videoFileDisplay.setValue(gateway.metadata.media[0].video.url);
            this.videoFileId.setValue(gateway.metadata.media[0].video._id);
            if (this.videoType === 'file')
                this.currentNode.video = { link: this.videoFileDisplay.value, _id: this.videoFileId.value };
            else
                this.currentNode.video = { link: this.videoUrlDisplay.value, _id: this.videoUrlId.value };
            this.currentNode.audio = { link: this.audioFileDisplay.value, _id: this.audioFileId.value };
            this.currentNode.image = { link: this.backgroundFileDisplay.value, _id: this.backgroundFileId.value };
        }
        else {
            if (isConditionNode) {
                this.toJsonTemplate.nodes.filter((i: any) => i.type === 'condition').find((j: any) => j.metadata.fields.forEach((k: any) => {
                    if (k.fieldId === id) {
                        this.currentNode.type = 'Option';
                        this.scoreFormControl.setValue(k.fieldScore);
                        this.maleContentFormControl.setValue(k.fieldValue.male);
                        this.femaleContentFormControl.setValue(k.fieldValue.female);
                        this.otherContentFormControl.setValue(k.fieldValue.other);
                        this.tagformControl.setValue(k.fieldTags);
                        this.feedbackControl.setValue(k.feedback);
                    }
                }));
            }
            else {
                let state = this.toJsonTemplate.nodes.find((i: any) => i.id === id);
                this.currentNode.type = state.type;
                this.maleContentFormControl.setValue(state.name.male);
                this.femaleContentFormControl.setValue(state.name.female);
                this.otherContentFormControl.setValue(state.name.other);

                if (state.metadata.media.length === 0)
                    state.metadata.media.push({ audio: {}, video: {}, background: {} });

                this.backgroundFileDisplay.setValue(state.metadata.media[0].background.url);
                this.backgroundFileId.setValue(state.metadata.media[0].background._id);
                this.audioFileDisplay.setValue(state.metadata.media[0].audio.url);
                this.audioFileId.setValue(state.metadata.media[0].audio._id);
                if (state.metadata.media[0].video.type === 'file') {
                    this.videoFileDisplay.setValue(state.metadata.media[0].video.url);
                    this.videoFileId.setValue(state.metadata.media[0].video._id);
                }
                else {
                    this.videoUrlDisplay.setValue(state.metadata.media[0].video.url);
                    this.videoUrlId.setValue(state.metadata.media[0].video._id);
                }
                this.tagformControl.setValue(state.metadata.tags);
                this.videoType = state.metadata.media[0].video.type;
                if (this.videoType === 'file')
                    this.currentNode.video = { link: this.videoFileDisplay.value, _id: this.videoFileId.value };
                else
                    this.currentNode.video = { link: this.videoUrlDisplay.value, _id: this.videoUrlId.value };
                this.currentNode.audio = { link: this.audioFileDisplay.value, _id: this.audioFileId.value };
                this.currentNode.image = { link: this.backgroundFileDisplay.value, _id: this.backgroundFileId.value };
            }
        }

        this.tempFormFields = {
            maleContent: this.maleContentFormControl.value,
            femaleContent: this.femaleContentFormControl.value,
            otherContent: this.otherContentFormControl.value,
            audio: this.audioFileDisplay.value,
            video: this.videoFileDisplay.value,
            videoUrl: this.videoUrlDisplay.value,
            background: this.backgroundFileDisplay.value,
            tags: this.tagformControl.value,
            score: this.scoreFormControl.value,
            feedback: this.feedbackControl.value
        };
    }

    async closeSidePanel(doSelect: boolean = true) {
        this.videoUrlDisplay.markAsTouched();
        if (this.currentNode?.id && (!this.videoUrlDisplay.value || (this.videoUrlDisplay.value && !this.videoUrlDisplay.hasError('pattern')))) {
            if (this.maleContentFormControl.value !== this.tempFormFields.maleContent || this.femaleContentFormControl.value !== this.tempFormFields.femaleContent || this.otherContentFormControl.value !== this.tempFormFields.otherContent || this.audioFileDisplay.value !== this.tempFormFields.audio || this.videoFileDisplay.value !== this.tempFormFields.video || this.videoUrlDisplay.value != this.tempFormFields.videoUrl || this.backgroundFileDisplay.value !== this.tempFormFields.background || this.tagformControl.value !== this.tempFormFields.tags || this.scoreFormControl.value !== this.tempFormFields.score || this.feedbackControl.value !== this.tempFormFields.feedback) {
                await this.save().then(() => {
                    let taskId = this.bpmnModeler.get('elementRegistry').get(this.currentNode.id);
                    if (doSelect) {
                        this.bpmnModeler.get('selection').select(taskId);
                        this.currentNode = {};
                        this.diagramChanged = false;
                        this.diagramService.diagramChangedLock.next(this.diagramChanged);
                    }
                });
            }
            else {
                let taskId = this.bpmnModeler.get('elementRegistry').get(this.currentNode.id);
                if (doSelect) {
                    this.bpmnModeler.get('selection').select(taskId);
                    this.currentNode = {};
                    this.diagramChanged = false;
                    this.diagramService.diagramChangedLock.next(this.diagramChanged);
                }
            }
        }
    }

    private loadDiagram() {
        this.loadService.display(true);
        // console.log('loading', this.diagramUrl)
        try {
            new Observable((observer: any) => {
                //Make use of Fetch API to get data from URL                              
                fetch(this.diagramUrl)
                    .then(res => {
                        return res.text();
                    })
                    .then(body => {
                        try {
                            observer.next(body);
                            observer.complete();
                        }
                        catch (e) { }
                    })
                    .catch(err => observer.error(err));
            }).subscribe(async (bpmnXML: any) => {
                try {
                    await this.bpmnModeler.importXML(bpmnXML);
                    // console.log(bpmnXML)
                    // let canvas = this.bpmnModeler.get('canvas');
                    // let overlays = this.bpmnModeler.get('overlays');

                    // zoom to fit full viewport
                    this.centerDiagram();

                    await this.convertToJsonTemplate(false);
                }
                catch (e) { }

                this.loadService.display(false);
            });
        }
        catch (e) { }
    }

    private centerDiagram() {
        try {
            let canvas = this.bpmnModeler.get('canvas');
            let viewbox = canvas.viewbox();

            // Calculate the current diagram width and height
            let diagramWidth = viewbox.width;
            let diagramHeight = viewbox.height;

            // Get the container element of the canvas
            let container = canvas.getContainer();

            // Get the container width and height
            let containerWidth = container.clientWidth;
            let containerHeight = container.clientHeight;

            // Get the element registry
            let elementRegistry = this.bpmnModeler.get('elementRegistry');

            // Get all BPMN elements in the modeler
            let elements = elementRegistry.getAll();

            // Calculate the minimum and maximum x-coordinates of the BPMN elements
            let minX = Infinity;
            let maxX = -Infinity;

            elements.forEach(function (element) {
                // console.log(element.x, element.width)
                minX = Math.min(minX, element.x || Infinity);
                maxX = Math.max(maxX, (element.x || -Infinity) + element.width);
                // console.log(minX, maxX)
            });

            // Calculate the width of the diagram
            diagramWidth = maxX - minX;

            // Calculate the desired zoom factor to fit the diagram within the container
            let zoomFactor = Math.min(containerWidth / diagramWidth, containerHeight / diagramHeight);

            // Zoom to the calculated zoom factor
            canvas.zoom(zoomFactor);

            canvas.scroll({
                dx: ((containerWidth / 2) - (diagramWidth / 2)), // Horizontal panning distance
                dy: 50   // Vertical panning distance
            });
        }
        catch (e) { }
    }

    async save() {
        if (this.currentNode.isCondition) {
            this.toJsonTemplate.nodes.filter((i: any) => i.type === 'condition').forEach((i: any) => {
                i.metadata.fields.forEach((k: any) => {
                    if (k.fieldId === this.currentNode.id) {
                        k.fieldScore = this.scoreFormControl.value + '';
                        k.fieldValue.male = this.maleContentFormControl.value;
                        k.fieldValue.female = this.femaleContentFormControl.value;
                        k.fieldValue.other = this.otherContentFormControl.value;
                        k.fieldTags = this.tagformControl.value;
                        k.feedback = this.feedbackControl.value;
                        return k;
                    }
                });
            });

            let maxTotalScore = 0;
            this.toJsonTemplate.nodes.filter(i => i.type === 'condition').forEach(element => {
                let score = 0;
                element.metadata.fields.forEach(option => {
                    if (Number(option.fieldScore) > score)
                        score = Number(option.fieldScore);
                });
                maxTotalScore += score;
            });
            this.toJsonTemplate.totalScore = maxTotalScore;

            this.flow = this.toJsonTemplate;
            await this.updateFlow();
            // console.log('this.toJsonTemplate', this.toJsonTemplate)
            // this.closeSidePanel();
        }
        else {
            let toUpload: any = [];
            if (this.audioFile) {
                toUpload.push({
                    type: 'audio',
                    file: this.audioFile
                });
            }
            if (this.videoFile) {
                toUpload.push({
                    type: 'video',
                    file: this.videoFile
                });
            }
            if (this.backgroundFile) {
                toUpload.push({
                    type: 'image',
                    file: this.backgroundFile
                });
            }

            if (toUpload.length) {
                this.loadService.display(true);
                await this.uploadMedia(toUpload).then(async results => {
                    this.updateFields();
                    this.flow = this.toJsonTemplate;
                    await this.updateFlow(true);
                    // console.log('this.toJsonTemplate', this.toJsonTemplate)
                    // this.closeSidePanel();
                });
            }
            else {
                this.updateFields();
                this.flow = this.toJsonTemplate;
                await this.updateFlow();
                // console.log('this.toJsonTemplate', this.toJsonTemplate)
                // this.closeSidePanel();
            }
        }
    }

    private updateFields() {
        this.toJsonTemplate.nodes.map((i: any) => {
            if ((i.type === 'state' || i.type === 'condition') && i.id === this.currentNode.id) {
                i.name.male = this.maleContentFormControl.value;
                i.name.female = this.femaleContentFormControl.value;
                i.name.other = this.otherContentFormControl.value;

                // if (i.type === 'state') {
                if (this.currentNode.audio)
                    i.metadata.media[0].audio = { url: this.audioFileDisplay.value, _id: this.audioFileId.value };

                if (this.currentNode.video) {
                    if (this.videoType === 'file')
                        i.metadata.media[0].video = { url: this.videoFileDisplay.value, _id: this.currentNode.video._id, type: this.videoType };
                    else
                        i.metadata.media[0].video = { url: this.videoUrlDisplay.value, _id: this.videoUrlId.value, type: this.videoType };
                }

                if (this.currentNode.image)
                    i.metadata.media[0].background = { url: this.backgroundFileDisplay.value, _id: this.backgroundFileId.value };
                // }
                // else if (i.type === 'condition') {
                //     if (!im.media) {
                //         i.media = [{ audio: {}, video: {}, background: {} }];
                //     }

                //     if (this.currentNode.audio)
                //         i.media[0].audio = { url: this.currentNode.audio.link, _id: this.currentNode.audio._id };

                //     if (this.currentNode.video)
                //         i.media[0].video = { url: this.currentNode.video.link, _id: this.currentNode.video._id };

                //     if (this.currentNode.image)
                //         i.media[0].background = { url: this.currentNode.image.link, _id: this.currentNode.image._id };
                // }
                i.metadata.tags = this.tagformControl.value;
                return i;
            }
        });
    }

    private showSmallLargeLoader(isSmall: boolean = true, bool: boolean) {
        if (isSmall) {
            this.loading = bool;
        }
        else {
            this.loadService.display(bool);
        }
    }

    private uploadMedia(toUpload: any): Promise<any> {
        let loaders: any = [];
        toUpload.forEach((obj: any) => {
            //validate file extensions
            let fileExt = obj.file.name.split('.').pop();
            if (obj.type === 'image' && this.allowedImageExtensions.indexOf(fileExt.toLowerCase()) === -1) {
                this.layoutUtilsService.showNotification(this.translate.instant('The file type is not allowed') + ': ' + obj.file.name, this.translate.instant('Dismiss'));
            }
            else if (obj.type === 'audio' && this.allowedAudioExtensions.indexOf(fileExt.toLowerCase()) === -1) {
                this.layoutUtilsService.showNotification(this.translate.instant('The file type is not allowed') + ': ' + obj.file.name, this.translate.instant('Dismiss'));
            }
            else if (obj.type === 'video' && this.allowedVideoExtensions.indexOf(fileExt.toLowerCase()) === -1) {
                this.layoutUtilsService.showNotification(this.translate.instant('The file type is not allowed') + ': ' + obj.file.name, this.translate.instant('Dismiss'));
            }
            else {
                loaders.push(new Promise<boolean>((resolve, reject) => {
                    this.requestService.uploadFile(this.flowId, this.flowId, obj.file, obj.type, 'flow/file/upload/', this.currentNode.id)
                        .subscribe(
                            (results: any) => {
                                this.currentNode[obj.type] = results.results;
                                resolve(true);
                            },
                            (error: any) => {
                                reject(false);
                            })
                }));
            }
        });
        return Promise.all(loaders);
    }

    zoomIn() {
        this.bpmnModeler.get('zoomScroll').stepZoom(1);
    }

    zoomOut() {
        this.bpmnModeler.get('zoomScroll').stepZoom(-1);
    }

    resetZoom() {
        this.bpmnModeler.get('canvas').zoom('fit-viewport', 'auto');
    }

    handleFileInputChange(file: FileList, type: string): void {
        if (type === 'background') {
            this.backgroundFileDisplay.patchValue(file[0].name);
            this.backgroundFile = file[0];
        }
        else if (type === 'audio') {
            this.audioFileDisplay.patchValue(file[0].name);
            this.audioFile = file[0];
        }
        else if (type === 'video') {
            this.videoFileDisplay.patchValue(file[0].name);
            this.videoFile = file[0];
        }
    }

    removeFile(type: string) {
        let id: string = '';
        if (type === 'background') {
            id = this.backgroundFileId.value || '';
            // if (!this.backgroundFileId.value) {
            this.backgroundFileDisplay.setValue('');
            this.backgroundFile = '';
            this.backgroundFileId.setValue('');
            // }
        }
        else if (type === 'audio') {
            id = this.audioFileId.value || '';
            // if (!this.audioFileId.value) {
            this.audioFileDisplay.setValue('');
            this.audioFile = '';
            this.audioFileId.setValue('');
            // }
        }
        else if (type === 'video') {
            id = this.videoFileId.value || '';
            // if (!this.videoFileId.value) {
            if (this.videoType == 'file') {
                this.videoFileDisplay.setValue('');
                this.videoFile = '';
                // }
                this.videoFileId.setValue('');
            }
            else {
                this.videoUrlDisplay.setValue('');
                this.videoUrlId.setValue('');
            }
        }

        if (id) {
            // remove from db
            this.loadService.display(true);
            this.requestService.deleteFile('flow/file', this.flowId, this.currentNode.id, type, (data, error) => {
                // console.log(data, error)
                if (data)
                    this.layoutUtilsService.showNotification(this.translate.instant('Deleted Successfully'), this.translate.instant('Dismiss'));

                this.toJsonTemplate.nodes.map((i: any) => {
                    if (i.id === this.currentNode.id && i.hasOwnProperty('metadata') && i.metadata.media[0].hasOwnProperty(type)) {
                        i.metadata.media[0][type] = {};
                    }
                });
                this.flow = this.toJsonTemplate;
                this.updateFlow(true);
                this.setFormVariables(this.currentNode.type, this.currentNode.id, this.currentNode.isCondition, this.currentNode.stateTitle);
                // console.log('this.toJsonTemplate', this.toJsonTemplate)
                this.loadService.display(false);
            });
        }
    }

    insertSpecialString(obj: any, control: FormControl) {
        let cursorPosition = obj.selectionStart;
        obj.value = [obj.value.slice(0, cursorPosition), "[X] ", obj.value.slice(cursorPosition)].join('');
        setTimeout(() => { cursorPosition = obj.selectionEnd = cursorPosition + 4; }, 0);
        control?.patchValue(obj.value);
    }

    private addErrorMessage(message: string) {
        if (this.validationErrors.indexOf(message) === -1)
            this.validationErrors.push(message);
    }

    async validate() {
        this.loadService.display(true);
        this.validationErrors = [];
        this.validatingStatus = 1;
        let elementRegistry = this.bpmnModeler.get('elementRegistry');

        // validate shapes
        let unsupportedShaped = elementRegistry.getAll().filter((i: any) => !['bpmn:Process', 'bpmn:Task', 'bpmn:ExclusiveGateway', 'bpmn:StartEvent', 'bpmn:EndEvent', 'bpmn:SequenceFlow', 'label'].includes(i.type));
        if (unsupportedShaped.length) {
            this.addErrorMessage(this.translate.instant('Remove unsupported shapes'));
            // this.layoutUtilsService.showNotification(this.translate.instant('The diagram includes unsupported shapes, please remove and try again.'), this.translate.instant('Dismiss'));
            this.validatingStatus = -1;
        }

        // if (this.validatingStatus === 2) {
        // this.toJsonTemplate = { nodes: [], connections: [], selectedOptions: [] };
        await this.exportDiagram();

        const parser = new DOMParser();
        let xml: any = parser.parseFromString(this.xml.xml, 'text/xml');

        if (xml) {
            let startNodes = xml.getElementsByTagName('bpmn:startEvent');
            let endNodes = xml.getElementsByTagName('bpmn:endEvent');
            if (startNodes.length > 1) {
                Array.from(startNodes).forEach((element: any, index: number) => {
                    if (index > 0) {
                        let taskId = elementRegistry.get(element.id);
                        this.highlightError(taskId);
                        this.resetError(taskId);
                    }
                });

                this.addErrorMessage(this.translate.instant('Diagram should have 1 start node'));
                // this.layoutUtilsService.showNotification(this.translate.instant('You can have only 1 start node.'), this.translate.instant('Dismiss'));
                this.validatingStatus = -1;
            }
            else if (startNodes.length === 0) {
                this.addErrorMessage(this.translate.instant('Missing Start node'));
                // this.layoutUtilsService.showNotification(this.translate.instant('You can have only 1 end node.'), this.translate.instant('Dismiss'));
                this.validatingStatus = -1;
            }

            if (endNodes.length > 1) {
                Array.from(endNodes).forEach((element: any, index: number) => {
                    if (index > 0) {
                        let taskId = elementRegistry.get(element.id);
                        this.highlightError(taskId);
                        this.resetError(taskId);
                    }
                });

                this.addErrorMessage(this.translate.instant('Diagram should have 1 end node'));
                // this.layoutUtilsService.showNotification(this.translate.instant('You can have only 1 end node.'), this.translate.instant('Dismiss'));
                this.validatingStatus = -1;
            }
            else if (endNodes.length === 0) {
                this.addErrorMessage(this.translate.instant('Missing End node'));
                // this.layoutUtilsService.showNotification(this.translate.instant('You can have only 1 end node.'), this.translate.instant('Dismiss'));
                this.validatingStatus = -1;
            }

            let conditions: any = xml.getElementsByTagName('bpmn:exclusiveGateway');
            if (conditions.length) {
                Array.from(conditions).forEach((e: any) => {
                    if (e) {
                        let taskId = elementRegistry.get(e.id);
                        if (taskId.incoming.find((conn: any) => taskId.outgoing.find((i: any) => i.target.id === conn.source.id))) {
                            // this.bpmnModeler.get('selection').select(taskId);
                            this.highlightError(taskId);
                            this.resetError(taskId);
                            this.addErrorMessage(this.translate.instant('Tasks have more than one connection between them'));
                            // this.layoutUtilsService.showNotification(this.translate.instant('Each exclusive gateway should have 1 incoming connection.'), this.translate.instant('Dismiss'));
                            this.validatingStatus = -1;
                        }
                    }
                });

                Array.from(conditions).filter((i: any) => i.getElementsByTagName('bpmn:outgoing').length === 0).forEach((e: any) => {
                    if (e) {
                        let taskId = elementRegistry.get(e.id);
                        this.highlightError(taskId);
                        this.resetError(taskId);
                        this.addErrorMessage(this.translate.instant('Exclusive gateway(s) has no outgoing connections'));
                        this.validatingStatus = -1;
                    }
                });
            }

            // if (this.validatingStatus !== -1) {
            let tasks: any = xml.getElementsByTagName('bpmn:task');
            if (tasks.length) {
                Array.from(tasks).forEach((e: any) => {
                    if (e) {
                        let taskId = elementRegistry.get(e.id);
                        if (taskId.incoming.find((conn: any) => taskId.outgoing.find((i: any) => i.target.id === conn.source.id))) {
                            // this.bpmnModeler.get('selection').select(taskId);
                            this.highlightError(taskId);
                            this.resetError(taskId);
                            this.addErrorMessage(this.translate.instant('Tasks have more than one connection between them'));
                            // this.layoutUtilsService.showNotification(this.translate.instant('Each task should have 1 incoming connection.'), this.translate.instant('Dismiss'));
                            this.validatingStatus = -1;
                        }
                    }
                });

                Array.from(tasks).filter((i: any) => (i.getElementsByTagName('bpmn:outgoing').length > 1)).forEach((e: any) => {
                    if (e) {
                        let taskId = elementRegistry.get(e.id);
                        // this.bpmnModeler.get('selection').select(taskId);
                        this.highlightError(taskId);
                        this.resetError(taskId);
                        this.addErrorMessage(this.translate.instant('Task(s) has more than 1 outgoing connection'));
                        // this.layoutUtilsService.showNotification(this.translate.instant('Each task should have 1 incoming connection.'), this.translate.instant('Dismiss'));
                        this.validatingStatus = -1;
                    }
                });

                Array.from(tasks).filter((i: any) => (i.getElementsByTagName('bpmn:outgoing').length === 0)).forEach((e: any) => {
                    if (e) {
                        let taskId = elementRegistry.get(e.id);
                        this.highlightError(taskId);
                        this.resetError(taskId);
                        this.addErrorMessage(this.translate.instant('Task(s) has no outgoing connections'));
                        this.validatingStatus = -1;
                    }
                });

                let startNode = elementRegistry.getAll().find((i: any) => i.type === 'bpmn:StartEvent');
                let startNodeConnection: any = startNode?.outgoing[0];
                let endNode: any = elementRegistry.getAll().find((i: any) => i.type === 'bpmn:EndEvent');
                let connectedNodes: any = new Set<string>();

                if (endNode?.incoming.length == 0) {
                    this.highlightError(endNode);
                    this.resetError(endNode);
                    this.addErrorMessage(this.translate.instant('End event has no incoming connections'));
                    this.validatingStatus = -1;
                }

                if (startNodeConnection) {
                    // let task = startNodeConnection.target;
                    connectedNodes.add(startNodeConnection);
                    // if (task) {
                    // connectedNodes.add(task.id);
                    this.processedNodes = [];
                    this.traverseNodes(elementRegistry, connectedNodes, endNode, startNode).then(value => {
                        connectedNodes = value;
                        let allTasks: any = xml.getElementsByTagName('bpmn:task');
                        let allGateways: any = xml.getElementsByTagName('bpmn:exclusiveGateway');
                        let outliers: any = Array.from(allTasks).concat(Array.from(allGateways)).filter((i: any) => !connectedNodes.has(i.id));
                        // console.log(outliers)
                        if (outliers.length) {
                            this.validatingStatus = -1;

                            outliers.forEach((i: any) => {
                                let taskId = elementRegistry.get(i.id);
                                this.highlightError(taskId);
                                this.resetError(taskId);
                            });

                            this.addErrorMessage(this.translate.instant('Unreachable task(s)'));
                        }
                    });
                    // }
                    // console.log('connectedNodes', connectedNodes)
                }
                else {
                    let taskId = elementRegistry.getAll().find((i: any) => i.type === 'bpmn:StartEvent');
                    this.highlightError(taskId);
                    this.resetError(taskId);
                    this.addErrorMessage(this.translate.instant('Start event has no outgoing connection'));
                    this.validatingStatus = -1;
                }
            }

            if (this.validatingStatus !== -1) {
                this.validatingStatus = 2;
                this.validationErrors = [];
            }
        }
        this.loadService.display(false);
    }

    async traverseNodes(elementRegistry: any, connectedNodes: any, endNode: any, task: any): Promise<any> {
        let processing = true;
        while (processing) {
            let sequence = elementRegistry.getAll().find((i: any) => i.id === task.id)?.outgoing[0];
            if (sequence) {
                connectedNodes.add(sequence.target.id);
                if (sequence.target.id === endNode.id)
                    processing = false;
                else {
                    if (sequence.target.type === 'bpmn:ExclusiveGateway') {
                        let gateway = elementRegistry.getAll().find((i: any) => i.id === sequence.target.id);
                        if (gateway) {
                            // console.log('gateway.outgoing', gateway.outgoing)
                            gateway.outgoing.forEach(async (element: any) => {
                                connectedNodes.add(element.target.id);
                                await this.traverseNodes(elementRegistry, connectedNodes, endNode, element.target);
                            });
                        }
                        // console.log('seq', sequence, gateway)
                        processing = false;
                    }
                    else {
                        if (!this.processedNodes.includes(sequence.target.id)) {
                            task = sequence.target;
                            this.processedNodes.push(task.id);
                        }
                        else {
                            processing = false;
                        }
                    }
                }
            }
            else
                processing = false;
        }
        return connectedNodes;
    }

    importExcel() {
        const dialogRef = this.dialog.open(ConfirmFileEntityDialogComponent, {
            data: {
                title: this.translate.instant('Import CSV File'),
                type: 'string',
                confirmbtn: this.translate.instant('Submit'),
                cancelbtn: this.translate.instant('Cancel'),
                file: {}
            },
            width: '40%',
            disableClose: false
        });
        dialogRef.afterClosed().subscribe((result: any) => {
            if (result) {
                if (result?.file && result.file.name.match('(.csv)$')?.length) {
                    try {
                        let file = result.file;
                        let reader = new FileReader();
                        reader.readAsBinaryString(file);
                        reader.onload = (e: any) => {
                            let data = e.target.result;
                            let workbook = XLSX.read(data, {
                                type: 'binary'
                            });
                            let result: any = {};
                            workbook.SheetNames.forEach(function (sheetName: any) {
                                let roa: any = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
                                if (roa.length > 0) {
                                    result[sheetName] = roa;
                                }
                            });
                            //displaying the json result

                            this.constructDiagramFromCSV(result);
                        }
                    } catch (e) {
                        console.error(e);
                    }
                }
                else {
                    this.layoutUtilsService.showNotification(this.translate.instant('URL should end with .csv'), this.translate.instant('Dismiss'));
                }
            }
        });
    }

    private async constructDiagramFromCSV(json: any) {
        // console.log('excel', json)
        this.loadService.display(true);
        this.diagramUrl = '/assets/bpmn/clear-diagram.bpmn';

        new Observable((observer: any) => {
            //Make use of Fetch API to get data from URL                              
            fetch(this.diagramUrl)
                .then(res => {
                    return res.text();
                })
                .then(body => {
                    observer.next(body);
                    observer.complete();
                })
                .catch(err => observer.error(err));
        }).subscribe(async (bpmnXML: any) => {
            await this.bpmnModeler.importXML(bpmnXML);
            // console.log(bpmnXML);

            let x = 50;
            let y = 50;

            // let canvas = this.bpmnModeler.get('canvas');
            let bpmnFactory = this.bpmnModeler.get('bpmnFactory');
            let elementRegistry = this.bpmnModeler.get('elementRegistry');
            let elementFactory = this.bpmnModeler.get('elementFactory');
            let modeling = this.bpmnModeler.get('modeling');
            let process = elementRegistry.get('Process_16wimb4');

            if (!json?.Sheet1?.length) {
                this.layoutUtilsService.showNotification(this.translate.instant('Imported file is empty.'), this.translate.instant('Dismiss'));
            }
            else {
                json.Sheet1.forEach((element: any, index: number) => {
                    let fieldName = element.Name.toLowerCase();
                    if (["shape", "imported shape", "terminator", "decision", "process"].includes(fieldName)) {
                        let keys = Object.keys(element);
                        let event: any = keys.find((i: any) => i.toLowerCase().indexOf('text area') !== -1);

                        if (event && ["shape", "imported shape"].includes(fieldName)) {
                            if (element[event].trim().toLowerCase() === 'start') {
                                this.addNode(process, bpmnFactory, elementFactory, modeling, 'bpmn:StartEvent', element.Id, '', x, y * index * 2);
                            }
                            else if (element[event].trim().toLowerCase() === 'end') {
                                this.addNode(process, bpmnFactory, elementFactory, modeling, 'bpmn:EndEvent', element.Id, '', x, y * index * 2);
                            }
                            else {
                                this.addNode(process, bpmnFactory, elementFactory, modeling, 'bpmn:Task', element.Id, element[event], x, y * index * 2);
                            }
                        }
                        else if (["terminator", "decision", "process"].includes(fieldName)) {
                            if (fieldName === 'terminator' && element[event].trim().toLowerCase() === 'start') {
                                this.addNode(process, bpmnFactory, elementFactory, modeling, 'bpmn:StartEvent', element.Id, '', x, y * index * 2);
                            }
                            else if (fieldName === 'terminator' && element[event].trim().toLowerCase() === 'end') {
                                this.addNode(process, bpmnFactory, elementFactory, modeling, 'bpmn:EndEvent', element.Id, '', x, y * index * 2);
                            }
                            else if (fieldName === 'process') {
                                this.addNode(process, bpmnFactory, elementFactory, modeling, 'bpmn:Task', element.Id, element[event], x, y * index * 2);
                            }
                            else if (fieldName === 'decision') {
                                this.addNode(process, bpmnFactory, elementFactory, modeling, 'bpmn:ExclusiveGateway', element.Id, '', x, y * index * 2);
                            }
                        }
                        else {
                            this.addNode(process, bpmnFactory, elementFactory, modeling, 'bpmn:ExclusiveGateway', element.Id, '', x, y * index * 2);
                        }
                    }
                });

                json.Sheet1.filter((i: any) => i.Name.toLowerCase() === 'line').forEach((element: any, index: number) => {
                    const businessObject = bpmnFactory.create("bpmn:SequenceFlow", {
                        name: ''
                    });

                    if (elementRegistry.get('node-' + element['Line Source'])?.parent && elementRegistry.get('node-' + element['Line Source']) && elementRegistry.get('node-' + element['Line Destination'])) {
                        modeling.createConnection(
                            elementRegistry.get('node-' + element['Line Source']),
                            elementRegistry.get('node-' + element['Line Destination']),
                            {
                                type: "bpmn:SequenceFlow",
                                businessObject: businessObject,
                            },
                            elementRegistry.get('node-' + element['Line Source']).parent
                        );
                    }
                });
                this.centerDiagram();
                this.resetZoom();
                this.validate();
            }

            this.loadService.display(false);
        });
        // console.log('xmlDoc', xmlDoc)
    }

    private addNode(process: any, bpmnFactory: any, elementFactory: any, modeling: any, type: string, elementId: string, elementName: string, x: number, y: number) {
        const businessObject = bpmnFactory.create(type, { id: 'node-' + elementId, name: elementName });
        const node = elementFactory.createShape({ type: type, businessObject: businessObject });
        modeling.createShape(node, { x: x, y: y }, process);
    }

    private resetError(taskId: any) {
        if (taskId)
            setTimeout(() => {
                this.bpmnModeler.get('modeling').setColor(taskId, {
                    stroke: 'black'
                });
            }, this.errorTimeout);
    }

    private highlightError(taskId: any) {
        if (taskId)
            this.bpmnModeler.get('modeling').setColor(taskId, {
                stroke: 'red'
            });
    }

    hideErrorDialog() {
        this.errorDialogTimeout = setTimeout(() => { this.showErrors = false }, 2000);
    }

    showErrorDialog() {
        clearTimeout(this.errorDialogTimeout);
        this.showErrors = true;
    }

    private canExit(message: string, nextUrl: string) {
        const dialogRef: any = this.layoutUtilsService.alertActionElement(this.translate.instant('Save Changes'), this.translate.instant(message), {
            overlayClickToClose: false,
            showCloseButton: true,
            confirmText: this.translate.instant('Save'),
            declineText: this.translate.instant('Close without Saving')
        });
        return dialogRef.afterClosed().subscribe(async (result: any) => {
            if (result) {
                if (result.action === 'confirmText') {
                    await this.convertToJsonTemplate(true, () => {
                        if (nextUrl === '/auth/login')
                            this.requestService.logOutApi();
                        else
                            this.router.navigate([nextUrl]);
                    });
                } else if (result.action === 'declineText') {
                    this.diagramChanged = false;
                    this.diagramService.diagramChangedLock.next(this.diagramChanged);
                    if (nextUrl === '/auth/login')
                        this.requestService.logOutApi();
                    else
                        this.router.navigate([nextUrl]);
                }
            }
        });
    }

    importVSDX() {
        const dialogRef = this.dialog.open(ConfirmFileEntityDialogComponent, {
            data: {
                title: this.translate.instant('Select VSDX File'),
                type: 'string',
                confirmbtn: this.translate.instant('Submit'),
                cancelbtn: this.translate.instant('Cancel')
            },
            width: '40%',
            disableClose: false
        });
        dialogRef.afterClosed().subscribe((result: any) => {
            // console.log('url uis', result)
            if (result) {
                if (result?.file && result.file.name.match('(.vsdx)$')?.length) {
                    this.loadService.display(true);
                    const formData = new FormData();
                    formData.append('file', result.file);
                    this.loadService.display(true);
                    // this.http.post('http://18.208.248.129:5000/api/upload',
                    //     formData).subscribe(
                    //         (response) => {
                    //             console.log(response)
                    //             this.validationErrors = [];
                    //             this.validatingStatus = 0;

                    //             this.constructDiagramFromVSDX(response);

                    //             this.centerDiagram();
                    //             this.validate();
                    //             this.loadService.display(false);
                    //         },
                    //         (error) => {
                    //             this.loadService.display(false);
                    //         });
                    this.requestService.uploadVSDX(result.file)
                        .subscribe(
                            (response: any) => {
                                console.log(response)
                                this.validationErrors = [];
                                this.validatingStatus = 0;

                                this.constructDiagramFromVSDX(response);

                                this.centerDiagram();
                                this.validate();
                                this.loadService.display(false);
                            },
                            (error) => {
                                this.loadService.display(false);
                            });
                }
                else {
                    this.layoutUtilsService.showNotification(this.translate.instant('URL should end with .vsdx'), this.translate.instant('Dismiss'));
                }
            }
        });
    }

    private constructDiagramFromVSDX(json: any) {
        this.loadService.display(true);
        this.diagramUrl = '/assets/bpmn/clear-diagram.bpmn';

        new Observable((observer: any) => {
            //Make use of Fetch API to get data from URL                              
            fetch(this.diagramUrl)
                .then(res => {
                    return res.text();
                })
                .then(body => {
                    observer.next(body);
                    observer.complete();
                })
                .catch(err => observer.error(err));
        }).subscribe(async (bpmnXML: any) => {
            await this.bpmnModeler.importXML(bpmnXML);
            // console.log(bpmnXML);

            // let canvas = this.bpmnModeler.get('canvas');
            let bpmnFactory = this.bpmnModeler.get('bpmnFactory');
            let elementRegistry = this.bpmnModeler.get('elementRegistry');
            let elementFactory = this.bpmnModeler.get('elementFactory');
            let modeling = this.bpmnModeler.get('modeling');
            let process = elementRegistry.get('Process_16wimb4');

            if (!json.states.length || !json.connections.length) {
                this.layoutUtilsService.showNotification(this.translate.instant('Imported file is empty.'), this.translate.instant('Dismiss'));
            }
            else {
                json.states.forEach(node => {
                    let nodeX = node.x * 100;
                    let nodeY = -node.y * 100;
                    // console.log(node.type.toLowerCase(), nodeX, nodeY);
                    if (node.type.toLowerCase() === 'start') {
                        this.addNode(process, bpmnFactory, elementFactory, modeling, 'bpmn:StartEvent', node.id, node.text, nodeX, nodeY);
                    }
                    else if (node.type.toLowerCase() === 'end') {
                        this.addNode(process, bpmnFactory, elementFactory, modeling, 'bpmn:EndEvent', node.id, node.text, nodeX, nodeY);
                    }
                    else if (node.type.toLowerCase() === 'task') {
                        this.addNode(process, bpmnFactory, elementFactory, modeling, 'bpmn:Task', node.id, node.text, nodeX, nodeY);
                    }
                    else {
                        this.addNode(process, bpmnFactory, elementFactory, modeling, 'bpmn:ExclusiveGateway', node.id, node.text, nodeX, nodeY);
                    }
                });

                json.connections.forEach((element: any, index: number) => {
                    const businessObject = bpmnFactory.create("bpmn:SequenceFlow", {
                        name: ''
                    });

                    if (elementRegistry.get('node-' + element.from)?.parent && elementRegistry.get('node-' + element.from) && elementRegistry.get('node-' + element.to)) {
                        modeling.createConnection(
                            elementRegistry.get('node-' + element.from),
                            elementRegistry.get('node-' + element.to),
                            {
                                type: "bpmn:SequenceFlow",
                                businessObject: businessObject,
                            },
                            elementRegistry.get('node-' + element.from).parent
                        );
                    }
                });

                // json.Sheet1.filter((i: any) => i.Name.toLowerCase() === 'line').forEach((element: any, index: number) => {
                //     const businessObject = bpmnFactory.create("bpmn:SequenceFlow", {
                //         name: ''
                //     });

                //     if (elementRegistry.get('node-' + element['Line Source'])?.parent && elementRegistry.get('node-' + element['Line Source']) && elementRegistry.get('node-' + element['Line Destination'])) {
                //         modeling.createConnection(
                //             elementRegistry.get('node-' + element['Line Source']),
                //             elementRegistry.get('node-' + element['Line Destination']),
                //             {
                //                 type: "bpmn:SequenceFlow",
                //                 businessObject: businessObject,
                //             },
                //             elementRegistry.get('node-' + element['Line Source']).parent
                //         );
                //     }
                // });

                // json.Sheet1.forEach((element: any, index: number) => {
                //     let fieldName = element.Name.toLowerCase();
                //     if (["shape", "imported shape", "terminator", "decision", "process"].includes(fieldName)) {
                //         let keys = Object.keys(element);
                //         let event: any = keys.find((i: any) => i.toLowerCase().indexOf('text area') !== -1);

                //         if (event && ["shape", "imported shape"].includes(fieldName)) {
                //             if (element[event].trim().toLowerCase() === 'start') {
                //                 this.addNode(process, bpmnFactory, elementFactory, modeling, 'bpmn:StartEvent', element.Id, '', x, y * index * 2);
                //             }
                //             else if (element[event].trim().toLowerCase() === 'end') {
                //                 this.addNode(process, bpmnFactory, elementFactory, modeling, 'bpmn:EndEvent', element.Id, '', x, y * index * 2);
                //             }
                //             else {
                //                 this.addNode(process, bpmnFactory, elementFactory, modeling, 'bpmn:Task', element.Id, element[event], x, y * index * 2);
                //             }
                //         }
                //         else if (["terminator", "decision", "process"].includes(fieldName)) {
                //             if (fieldName === 'terminator' && element[event].trim().toLowerCase() === 'start') {
                //                 this.addNode(process, bpmnFactory, elementFactory, modeling, 'bpmn:StartEvent', element.Id, '', x, y * index * 2);
                //             }
                //             else if (fieldName === 'terminator' && element[event].trim().toLowerCase() === 'end') {
                //                 this.addNode(process, bpmnFactory, elementFactory, modeling, 'bpmn:EndEvent', element.Id, '', x, y * index * 2);
                //             }
                //             else if (fieldName === 'process') {
                //                 this.addNode(process, bpmnFactory, elementFactory, modeling, 'bpmn:Task', element.Id, element[event], x, y * index * 2);
                //             }
                //             else if (fieldName === 'decision') {
                //                 this.addNode(process, bpmnFactory, elementFactory, modeling, 'bpmn:ExclusiveGateway', element.Id, '', x, y * index * 2);
                //             }
                //         }
                //         else {
                //             this.addNode(process, bpmnFactory, elementFactory, modeling, 'bpmn:ExclusiveGateway', element.Id, '', x, y * index * 2);
                //         }
                //     }
                // });

                // json.Sheet1.filter((i: any) => i.Name.toLowerCase() === 'line').forEach((element: any, index: number) => {
                //     const businessObject = bpmnFactory.create("bpmn:SequenceFlow", {
                //         name: ''
                //     });

                //     if (elementRegistry.get('node-' + element['Line Source'])?.parent && elementRegistry.get('node-' + element['Line Source']) && elementRegistry.get('node-' + element['Line Destination'])) {
                //         modeling.createConnection(
                //             elementRegistry.get('node-' + element['Line Source']),
                //             elementRegistry.get('node-' + element['Line Destination']),
                //             {
                //                 type: "bpmn:SequenceFlow",
                //                 businessObject: businessObject,
                //             },
                //             elementRegistry.get('node-' + element['Line Source']).parent
                //         );
                //     }
                // });
                this.centerDiagram();
                this.resetZoom();
                this.validate();
            }

            this.loadService.display(false);
        });
    }

    showInputDialog() {
        if (this.videoFileId.value) {
            const dialog = this.dialog.open(AlertEntityDialogComponent, {
                data: {
                    title: this.translate.instant('Delete existing file before adding a video URL.'),
                    // title: ''
                }, width: 'fit-content'
            });
        }
        else {
            this.videoType = 'url';
            this.changeDetectorRef.detectChanges();
            this.videoUrlInput.nativeElement.focus();
        }
        // const dialogRef = this.dialog.open(ConfirmInputEntityDialogComponent, {
        //     data: {
        //         title: this.translate.instant('Video URL'),
        //         type: 'string',
        //         confirmbtn: this.translate.instant('Submit'),
        //         cancelbtn: this.translate.instant('Cancel')
        //     },
        //     width: '40%',
        //     disableClose: false
        // });
        // dialogRef.afterClosed().subscribe((result: any) => {
        //     if (result) {
        //         this.videoUrlDisplay.setValue(result);
        //     }
        // });
    }

    showVideoInput() {
        this.videoUrlDisplay.setValue('');
        this.videoType = 'file';
        this.changeDetectorRef.detectChanges();
        this.videoFileInput.nativeElement.click();
    }
}