import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { fromEvent, debounceTime, distinctUntilChanged, tap } from "rxjs";
import { AuthenticationService } from "src/app/core/services/auth.service";
import { LayoutUtilsService } from "src/app/core/services/layout-utils.service";
import { RequestService } from "src/app/core/services/request.service";
import { environment } from "src/environments/environment";

@Component({
    selector: 'app-flow-assign',
    templateUrl: './flow-assign.component.html',
    styleUrls: ['./flow-assign.component.scss']
})

export class FlowAssignComponent implements OnInit, OnDestroy, AfterViewInit {

    public loadingUsers: boolean = false;
    public loadingFlows: boolean = false;
    public users: any = [];
    public flows: any = [];
    public assignedFlows: any = [];
    public selectedUser: string = '';
    public selectedAllFlowsChecked: boolean = false;
    public selectedAllUsersChecked: boolean = false;
    public enableButtons: boolean = false;
    public todaysDate: Date = new Date();
    public dueDate: Date = undefined;
    public isMultiple: string = 'single';
    public currentUser: any;
    private subscriptions: any[] = <any>[];

    @ViewChild('usersearch') usersearch!: ElementRef;
    @ViewChild('flowsearch') flowsearch!: ElementRef;

    constructor(private requestService: RequestService, private translate: TranslateService, private layoutUtilsService: LayoutUtilsService, private dialogRef: MatDialogRef<FlowAssignComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any, private authService: AuthenticationService) {
    }

    ngOnInit() {
        this.subscriptions.push(this.requestService.currentUserSubject.subscribe((value) => {
            if (value) {
                this.currentUser = value;
                let id = value._id;
                if (this.requestService.selectedClientIdForDashboard)
                    id = this.requestService.selectedClientIdForDashboard;

                this.getUsers();
                this.getFlows(id);
            }
        }));
    }

    ngAfterViewInit(): void {
        this.subscriptions.push(fromEvent(this.usersearch.nativeElement, 'keyup').pipe(
            debounceTime(500),
            distinctUntilChanged(),
            tap(() => {
                try {
                    this.getUsers(this.usersearch.nativeElement.value);
                } catch (e) { }
            })
        ).subscribe());

        this.subscriptions.push(fromEvent(this.flowsearch.nativeElement, 'keyup').pipe(
            debounceTime(500),
            distinctUntilChanged(),
            tap(() => {
                try {
                    this.getFlows(undefined, this.flowsearch.nativeElement.value);
                } catch (e) { }
            })
        ).subscribe());
    }

    ngOnDestroy() {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    closeDialog(data?: any) {
        this.dialogRef.close(data);
    }

    saveAssignments(isAssigning: boolean) {
        if (this.dueDate < new Date()) {
            this.layoutUtilsService.showNotification(this.translate.instant('Due Date should be in the future.'), this.translate.instant('Dismiss'));
        }
        else {
            this.loadingUsers = true;
            this.loadingFlows = true;

            let flowsToAdd: any = {};
            this.users.filter(i => i.checked).map(j => {
                this.flows.filter(k => k.checked).forEach(element => {
                    if (!j.flows?.includes(element._id)) {
                        if (flowsToAdd.hasOwnProperty(j._id)) {
                            let flows = flowsToAdd[j._id] || [];
                            flows.push({
                                _id: element._id,
                                name: '',
                                dueDate: this.dueDate ? moment.utc(this.dueDate).toISOString() : '',
                                submissionType: this.isMultiple ? 'multiple_submission' : 'single_submission'
                            });
                            flowsToAdd[j._id] = flows;
                        }
                        else {
                            flowsToAdd[j._id] = [{
                                _id: element._id,
                                name: '',
                                dueDate: this.dueDate ? moment.utc(this.dueDate).toISOString() : '',
                                submissionType: this.isMultiple ? 'multiple_submission' : 'single_submission'
                            }];
                        }
                    }
                });
            });

            let obj = {
                data: flowsToAdd
            };

            if (isAssigning) {
                this.requestService.saveRecord(obj, 'user/flow/assign', (data, error) => {
                    if (data) {
                        this.layoutUtilsService.showNotification(this.translate.instant('Saved Successfully'), this.translate.instant('Dismiss'));
                    }
                    if (error) {
                        this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
                    }
                    this.loadingUsers = false;
                    this.loadingFlows = false;
                    this.closeDialog();
                });
            }
            else {
                this.requestService.saveRecord(obj, 'user/flow/unassign', (data, error) => {
                    if (data) {
                        this.layoutUtilsService.showNotification(this.translate.instant('Saved Successfully'), this.translate.instant('Dismiss'));
                    }
                    if (error) {
                        this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
                    }
                    this.loadingUsers = false;
                    this.loadingFlows = false;
                    this.closeDialog();
                });
            }
        }
    }

    getUsers(term?: string) {
        this.loadingUsers = true;
        this.requestService.getDataList('user', {
            page: 1, term: term || '', termfields: ['name'], filter: {
                "$and": [
                    { "resources._id": { "$eq": environment.customKeys.roleView } },
                    { 'organizationId._id': { '$eq': this.requestService.orgId } },
                ]
            }, orderBy: 'name', orderDir: 'asc', fieldKeys: ['email', 'firstName', 'lastName', 'pictureLink', 'resources', 'flows']
        }, (data, error) => {
            if (data) {
                this.users = data.results;
            }
            else if (error) {
                this.layoutUtilsService.showNotification('Error: ' + error, this.translate.instant('Dismiss'));
            }
            this.loadingUsers = false;
        });
    }

    getFlows(id: string, term?: string) {
        this.loadingFlows = true;
        this.requestService.getDataList('flow', {
            page: 1, term: term || '', termfields: ['name'], filter: {
                "$and": [
                    // { "createdBy": { "$eq": id } },
                    { 'organizationId._id': { '$eq': this.requestService.orgId } },
                ]
            }, orderBy: 'name', orderDir: 'asc', fieldKeys: ['_id', 'flowId', 'name', 'description', 'versionId']
        }, (data, error) => {
            if (data) {
                this.flows = data.results;
                this.loadingFlows = false;
            }
            if (error) {
                this.layoutUtilsService.showNotification('Error: ' + error, this.translate.instant('Dismiss'));
            }
            this.loadingFlows = false;
        });
    }

    highlightAssignments(userId: string) {
        if (userId) {
            this.assignedFlows = [];
            this.selectedUser = userId;
            let findUser = this.users.find(i => i._id === userId);
            if (findUser) {
                findUser.flows?.map(i => {
                    this.assignedFlows.push(i._id);
                });
                // this.dueDate = findUser.dueDate;
                // this.isMultiple = findUser.submissionType === 'multiple_submission' ? 'multiple' : 'single';
            }
        }
    }

    selectAllUsers() {
        this.users.map(i => i.checked = this.selectedAllUsersChecked);
        this.validateButtons();
    }

    selectAllFlows() {
        this.flows.map(i => i.checked = this.selectedAllFlowsChecked);
        this.validateButtons();
    }

    validateButtons(user?: any) {
        if (user?.checked)
            this.highlightAssignments(user._id);

        this.enableButtons = this.users.find(i => i.checked) && this.flows.find(i => i.checked);
    }
}