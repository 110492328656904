import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { LayoutUtilsService } from "src/app/core/services/layout-utils.service";
import { RequestService } from "src/app/core/services/request.service";
import { CustomImageCropperDialogComponent } from "../image-cropper-dialog/image-cropper-dialog.component";

@Component({
    selector: 'app-characters',
    templateUrl: './characters.component.html',
    styleUrls: ['./characters.component.scss']
})

export class CharactersComponent implements OnInit, OnDestroy {

    // name: string = '';
    // gender: string = '';
    private currentUser: any;
    characterId: string = '';
    public imageExtension: string = 'png';
    public allowedExtensions: string[] = ['jpeg', 'jpg', 'png', 'gif'];
    public defaultPictureLink: string = 'assets/images/profile.png';
    public pictureLink: string = JSON.parse(JSON.stringify(this.defaultPictureLink));
    public originalData: any = undefined;
    loading: boolean = false;
    imageChanged: any = undefined;

    nameFormControl = new FormControl('', Validators.required);
    genderFormControl = new FormControl('', Validators.required);
    ageFormControl = new FormControl('', Validators.required);
    descriptionFormControl = new FormControl('', Validators.required);

    private subscriptions: any[] = <any>[];

    constructor(private requestService: RequestService, private layoutUtilsService: LayoutUtilsService, private translate: TranslateService, private dialog: MatDialog, private dialogRef: MatDialogRef<CharactersComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        if (data.id) {
            this.characterId = data.id;
            this.getCharacter(this.characterId);
        }
    }

    ngOnInit() {
        this.subscriptions.push(this.requestService.currentUserSubject.subscribe((value) => {
            if (value) {
                this.currentUser = value;
            }
        }));
    }

    ngOnDestroy() {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    getCharacter(id: string) {
        this.loading = true;
        this.requestService.getRecord(id, 'character', (data: any, error: any) => {
            // console.log(data, error)
            this.nameFormControl.setValue(data.results.name);
            this.genderFormControl.setValue(data.results.gender);
            this.ageFormControl.setValue(data.results.age);
            this.descriptionFormControl.setValue(data.results.description);
            this.pictureLink = data.results.pictureLink || this.defaultPictureLink;
            this.loading = false;
        });
    }

    private validate(): boolean {
        this.nameFormControl.markAsTouched();
        this.genderFormControl.markAsTouched();
        this.ageFormControl.markAsTouched();
        this.descriptionFormControl.markAsTouched();
        return this.nameFormControl.hasError('required') || this.genderFormControl.hasError('required') || this.ageFormControl.hasError('required') || this.descriptionFormControl.hasError('required');
    }

    saveCharacter() {
        if (!this.validate()) {
            this.loading = true;
            this.requestService.saveRecord({
                name: this.nameFormControl.value,
                organizationId: this.requestService.orgId,
                gender: this.genderFormControl.value,
                age: this.ageFormControl.value,
                description: this.descriptionFormControl.value
            }, 'character', (data: any, error: any) => {
                // console.log(data, error)

                if (data) {
                    if (this.imageChanged) {
                        const img: any = this.dataURLtoFile(this.imageChanged, data.results._id + '.' + this.imageExtension);
                        this.uploadImage(img, data.results._id, () => {
                            this.loading = false;
                            this.dialogRef.close({ redirectTo: '/characters/list' });
                            this.layoutUtilsService.showNotification(this.translate.instant('Saved Successfully'), this.translate.instant('Dismiss'));
                        });
                    }
                    else {
                        this.loading = false;
                        this.dialogRef.close({ redirectTo: '/characters/list' });
                        this.layoutUtilsService.showNotification(this.translate.instant('Saved Successfully'), this.translate.instant('Dismiss'));
                    }
                }
                if (error) {
                    this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
                    this.loading = false;
                }
            });
        }
    }

    updateCharacter() {
        if (!this.validate()) {
            this.loading = true;
            this.requestService.updateRecord({
                name: this.nameFormControl.value,
                organizationId: this.requestService.orgId,
                gender: this.genderFormControl.value,
                age: this.ageFormControl.value,
                description: this.descriptionFormControl.value
            }, 'character', this.characterId, (data: any, error: any) => {
                // console.log(data, error)

                if (data) {
                    if (this.imageChanged) {
                        const img: any = this.dataURLtoFile(this.imageChanged, this.characterId + '.' + this.imageExtension);
                        this.uploadImage(img, this.characterId, () => {
                            this.loading = false;
                            this.dialogRef.close({ redirectTo: '/characters/list' });
                            this.layoutUtilsService.showNotification(this.translate.instant('Updated Successfully'), this.translate.instant('Dismiss'));
                        });
                    }
                    else {
                        this.loading = false;
                        this.dialogRef.close({ redirectTo: '/characters/list' });
                        this.layoutUtilsService.showNotification(this.translate.instant('Updated Successfully'), this.translate.instant('Dismiss'));
                    }
                }
                if (error) {
                    this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
                    this.loading = false;
                }
            });
        }
    }

    public showImageCropper() {
        // const dataUriRegex = /^data:(.*?)(;base64)?,(.*)$/;

        const dialog = this.dialog.open(CustomImageCropperDialogComponent, {
            disableClose: false,
            autoFocus: true,
            width: '70vw',
            data: {
                title: this.translate.instant('Crop Image'),
                image: this.pictureLink,
                id: this.characterId
            }
        });
        dialog.afterClosed().subscribe((result: any) => {
            if (result) {
                this.imageChanged = result;
                // console.log('result', result)
                // const img: any = this.dataURLtoFile(result, this.characterId + '.' + this.imageExtension);
                this.pictureLink = result;
                // this.uploadImage(img);
            }
        });
    }

    public uploadImage(file: any, id: string = this.characterId, callback?: any) {
        this.loading = true;
        // console.log(file)
        this.requestService.uploadCharacterImage(id, file.name, file)
            .subscribe(
                (results: any) => {
                    this.pictureLink = results.results.pictureLink;
                    this.loading = false;
                    this.layoutUtilsService.showNotification(this.translate.instant('Image uploaded successfully'), this.translate.instant('Dismiss'));

                    if (callback)
                        callback();
                },
                (error: any) => {
                    this.pictureLink = JSON.parse(JSON.stringify(this.defaultPictureLink));
                    this.loading = false;
                    this.layoutUtilsService.showNotification('Error:' + error, 'Dismiss');
                }
            );
    }

    closeDialog(data?: any) {
        this.dialogRef.close(data);
    }

    dataURLtoFile(dataurl: any, filename: any) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }
}