<div class="container" fxLayout="row" fxLayoutAlign="center none">
    <div fxFlex="95%">
        <div class="container" fxLayout="column">
            <div fxLayout="row" fxLayoutGap="0.5vw" fxLayoutAlign="end center">
                <mat-form-field fxFlex class="search-input" fxGrow="1" appearance="outline">
                    <mat-icon matPrefix>search</mat-icon>
                    <input matInput placeholder="Search by name..." #searchInput>
                </mat-form-field>
                <div fxFlex fxGrow="0" fxLayoutAlign="center" fxLayout="row" fxLayoutGap="0.5vw">
                    <button fxFlex mat-raised-button color="primary" (click)="assignFlows()" [matTooltip]="'Assign Flows'
                        | translate" *ngIf="type !== 'admin'">
                        {{'Assign Flows' |
                        translate}}</button>
                    <button fxFlex mat-raised-button color="primary" (click)="addClient()"
                        [matTooltip]="'Create ' + displayType | translate">
                        {{' Create ' + displayType |
                        translate}}</button>
                    <button fxFlex mat-raised-button color="primary" (click)="importUsers()" *ngIf="type !== 'admin'"
                        [matTooltip]="'Import ' + displayType | translate">
                        {{' Import ' + displayType + 's' | translate}}</button>
                    <button fxFlex mat-raised-button color="primary" (click)="importClients()" *ngIf="type === 'admin'"
                        [matTooltip]="'Import ' + displayType | translate">
                        {{' Import ' + displayType + 's' | translate}}</button>
                </div>
            </div>
            <div fxFlex class="no-records animate">
                <table mat-table [dataSource]="datasource" class="mat-elevation-z8 table"
                    *ngIf="datasource && type === 'admin'">
                    <ng-container matColumnDef="pictureLink">
                        <th mat-header-cell *matHeaderCellDef style="width: 60px;"></th>
                        <td mat-cell *matCellDef="let element">
                            <img class="circle" [src]="element.pictureLink || defaultPictureLink"
                                style="width: 30px; height: 30px;">
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef> {{'Name' | translate}} </th>
                        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                    </ng-container>
                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef> {{'Email' | translate}} </th>
                        <td mat-cell *matCellDef="let element"> {{element.email}} </td>
                    </ng-container>
                    <ng-container matColumnDef="tools">
                        <th mat-header-cell *matHeaderCellDef style="width: 20%;"></th>
                        <td mat-cell *matCellDef="let element" class="tools">
                            <mat-icon (click)="editClient(element)" [matTooltip]="'Edit ' + displayType | translate">
                                edit</mat-icon>
                            <mat-icon (click)="deleteClient(element._id)"
                                [matTooltip]="' Delete ' + displayType | translate">
                                delete
                            </mat-icon>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedClientsColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedClientsColumns;"></tr>
                </table>

                <table mat-table [dataSource]="datasource" class="mat-elevation-z8 table"
                    *ngIf="datasource && type !== 'admin'">
                    <ng-container matColumnDef="pictureLink">
                        <th mat-header-cell *matHeaderCellDef style="width: 60px;"></th>
                        <td mat-cell *matCellDef="let element">
                            <img class="circle" [src]="element.pictureLink || defaultPictureLink"
                                style="width: 30px; height: 30px;">
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef> {{'Name' | translate}} </th>
                        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                    </ng-container>
                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef> {{'Email' | translate}} </th>
                        <td mat-cell *matCellDef="let element"> {{element.email}} </td>
                    </ng-container>
                    <ng-container matColumnDef="role">
                        <th mat-header-cell *matHeaderCellDef> {{'Role' | translate}} </th>
                        <td mat-cell *matCellDef="let element"> {{element.role | translate}} </td>
                    </ng-container>
                    <ng-container matColumnDef="tools">
                        <th mat-header-cell *matHeaderCellDef style="width: 20%;"></th>
                        <td mat-cell *matCellDef="let element" class="tools">
                            <mat-icon (click)="editClient(element)" [matTooltip]="'Edit ' + displayType | translate">
                                edit</mat-icon>
                            <mat-icon (click)="deleteClient(element._id)"
                                [matTooltip]="' Delete ' + displayType | translate">
                                delete
                            </mat-icon>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedUsersColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedUsersColumns;"></tr>
                </table>

                <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons (page)="getClients($event)"
                    [pageIndex]="pageIndex" [pageSize]="pageSize">
                </mat-paginator>
            </div>
        </div>
    </div>
</div>