// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
//  The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  orgId: '5e85c5820c22d9223a15d489',
  serverUrl: 'https://staging.api.flow.interactivelife.me/scenario/',
  customKeys: {
    roleAdmin: '5eb137f1d3123a407c7c97cc',// Admin
    roleView: '5ee76348d3123a0ef0d8cb63',
    roleOwner: '64e4e8b8ecacdcb0808f271d'
  },
  lockSite: false,
  rollbar: {
    apiKey: '9e35e7ce61164b038c72b785eb2d5fca'
  },
  environment: 'staging',
  casUrl: 'https://staging.api.flow.interactivelife.me/scenario/',
  productId: 'cc0b1284-ac68-4820-9853-48bf18d7c11f',
  s3: 'https://stellar-images-bucket-staging.s3.amazonaws.com/',
  aliveAppIntervalMinutes: 30, // if you keep app inactive
  aliveCheckAppIntervalMinutes: 60, // if you keep app inactive pop a message
};
