<div class="kt-portlet">
    <div class="kt-portlet__head kt-portlet__head__custom">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">{{data.title}}</h3>
            <span class="kt-portlet__head-icon kt-hide">
                <i class="la la-gear"></i>
            </span>
        </div>
    </div>
    <div class="kt-form">
        <div class="kt-portlet__body">
            <mat-spinner [diameter]="50" class="loader" *ngIf="loading"></mat-spinner>
            <div class="container" *ngIf="!loading">
                <div fxLayout="column" fxLayoutGap="0.5vw">
                    <mat-form-field appearance="fill" fxFlex *ngIf="_id">
                        <mat-label>{{'Version' | translate}}</mat-label>
                        <input matInput [(ngModel)]="version" placeholder="" readonly>
                    </mat-form-field>
                    <mat-form-field appearance="fill" fxFlex>
                        <mat-label>{{'Name' | translate}}</mat-label>
                        <input maxlength="50" matInput placeholder="" required [formControl]="nameFormControl">
                        <mat-error *ngIf="nameFormControl.hasError('required')">
                            {{'Name is required' | translate}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill" fxFlex>
                        <mat-label>{{'Opening Act' | translate}}</mat-label>
                        <mat-select name="openingAct" multiple [formControl]="openingActFormControl"
                            [compareWith]="compareObjects">
                            <mat-option *ngFor="let act of openingActs" [value]="{_id: act._id, name: act.name}">
                                {{act.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill" fxFlex *ngIf="openingActFormControl.value.length > 1">
                        <mat-label>{{'Opening Acts Availability' | translate}}</mat-label>
                        <mat-select name="openingActAvailability" [formControl]="openingActAvailabilityFormControl">
                            <mat-option value="random">{{'Randomize' | translate}}</mat-option>
                            <mat-option value="userselection">{{'Allow user selection' | translate}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill" fxFlex>
                        <mat-label>{{'Characters' | translate}}</mat-label>
                        <mat-select name="character" [compareWith]="compareObjects"
                            [formControl]="charactersFormControl" multiple>
                            <mat-option *ngFor="let character of characters"
                                [value]="{_id: character._id, name: character.name}">
                                {{character.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill" fxFlex *ngIf="charactersFormControl.value.length > 1">
                        <mat-label>{{'Characters Availability' | translate}}</mat-label>
                        <mat-select name="characterAvailability" [formControl]="characterAvailabilityFormControl">
                            <mat-option value="random">{{'Randomize' | translate}}</mat-option>
                            <mat-option value="userselection">{{'Allow user selection' | translate}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{'Description' | translate}}</mat-label>
                        <textarea maxlength="300" matInput placeholder="" rows="5"
                            [formControl]="descriptionFormControl"></textarea>
                        <mat-error *ngIf="descriptionFormControl.hasError('required')">
                            {{'Description is required' | translate}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill" fxFlex>
                        <mat-label>{{'Average completion time (in minutes)' | translate}}</mat-label>
                        <input matInput placeholder="" type="number" min="0"
                            [formControl]="averageCompletionFormControl">
                        <mat-error
                            *ngIf="!averageCompletionFormControl.hasError('pattern') && averageCompletionFormControl.invalid">
                            {{'Average completion should be a positive number' | translate}}
                        </mat-error>
                        <mat-error *ngIf="averageCompletionFormControl.hasError('pattern')">
                            {{'Invalid integer value.' | translate}}
                        </mat-error>
                    </mat-form-field>
                    <mat-checkbox fxFlex [(ngModel)]="allowReflection">
                        {{'Enable Reflection' | translate}}
                    </mat-checkbox>
                    <mat-checkbox fxFlex [(ngModel)]="allowDecisionFeedback">
                        {{'Feedback on Decisions' | translate}}
                    </mat-checkbox>
                    <mat-checkbox fxFlex [(ngModel)]="isDefault" [disabled]="flowCount === 1">
                        {{'Default Version' | translate}}
                    </mat-checkbox>
                </div>
            </div>
        </div>
        <div class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit text-right">
            <div class="kt-form__actions kt-form__actions--solid">
                <br />
                <button mat-raised-button (click)="closeDialog()">{{'Cancel' |
                    translate}}</button>&nbsp;
                <button mat-raised-button color="primary" *ngIf="!_id" (click)="saveFlow()"
                    [disabled]="!nameFormControl.valid || !descriptionFormControl.valid || !averageCompletionFormControl.valid">{{'Save'
                    |
                    translate}}</button>
                <button mat-raised-button color="primary" *ngIf="_id" (click)="updateFlow()">{{'Update'
                    |
                    translate}}</button>
            </div>
        </div>
    </div>
</div>