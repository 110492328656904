<div class="container" fxLayout="row" fxLayoutAlign="center none">

  <div fxFlex="95%">
    <!-- <div class="container" fxLayout="row" fxLayoutAlign="center none">
      <h2>Welcome back, {{currentUser.firstName}}!</h2>
    </div> -->

    <div class="container" fxLayout="column">
      <div fxLayout="row" fxLayoutGap="0.5vw" fxLayoutAlign="end center">
        <mat-form-field fxFlex class="search-input" fxGrow="1" appearance="outline">
          <mat-icon matPrefix>search</mat-icon>
          <input matInput placeholder="Search by name..." #searchInput>
        </mat-form-field>
        <div fxFlex fxGrow="0" fxLayoutAlign="center">
          <button mat-raised-button color="primary" (click)="openCreateFlow()"
            [matTooltip]="'Create Flow' | translate">{{'New
            Flow' |
            translate}}</button>
        </div>
      </div>
      <div fxFlex class="no-records animate">
        <table mat-table [dataSource]="flowTableDatasource" class="mat-elevation-z8 flow-table"
          *ngIf="flowTableDatasource">
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef style="width: 20%;"> {{'Name' | translate}} </th>
            <td mat-cell *matCellDef="let element" [style.background-color]="element.backgroundColor"> {{element.name}}
            </td>
          </ng-container>
          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef style="width: 20%;"> {{'Description' | translate}} </th>
            <td mat-cell *matCellDef="let element" [style.background-color]="element.backgroundColor">
              {{element.description | truncate: 300}} </td>
          </ng-container>
          <ng-container matColumnDef="openingAct">
            <th mat-header-cell *matHeaderCellDef> {{'Opening Act' | translate}} </th>
            <td mat-cell *matCellDef="let element" [style.background-color]="element.backgroundColor">
              {{element.openingAct }} </td>
          </ng-container>
          <ng-container matColumnDef="character">
            <th mat-header-cell *matHeaderCellDef> {{'Character' | translate}} </th>
            <td mat-cell *matCellDef="let element" [style.background-color]="element.backgroundColor">
              {{element.character}} </td>
          </ng-container>
          <ng-container matColumnDef="version">
            <th mat-header-cell *matHeaderCellDef style="width: 5%;text-align: center;"> {{'Version #' | translate}}
            </th>
            <td mat-cell *matCellDef="let element" style="text-align: center;"
              [style.background-color]="element.backgroundColor"> {{element.versionId}} </td>
          </ng-container>
          <ng-container matColumnDef="flow">
            <th mat-header-cell *matHeaderCellDef style="width: 5%;text-align: center;"> {{'Diagram Available' |
              translate}} </th>
            <td mat-cell *matCellDef="let element" style="text-align: center;"
              [style.background-color]="element.backgroundColor">
              <mat-icon *ngIf="element.flow" style="color: green;">check</mat-icon>
              <mat-icon *ngIf="!element.flow" style="color: red;">close</mat-icon>
            </td>
          </ng-container>
          <ng-container matColumnDef="default">
            <th mat-header-cell *matHeaderCellDef style="width: 5%;text-align: center;"> {{'Default' | translate}} </th>
            <td mat-cell *matCellDef="let element" style="text-align: center;"
              [style.background-color]="element.backgroundColor">
              <mat-checkbox [(ngModel)]="element.default" [disabled]="true"></mat-checkbox>
            </td>
          </ng-container>
          <ng-container matColumnDef="tools">
            <th mat-header-cell *matHeaderCellDef style="width: 10%;"></th>
            <td mat-cell *matCellDef="let element" class="tools" [style.background-color]="element.backgroundColor">
              <mat-icon [matTooltip]="'Preview Flow' | translate">play_arrow</mat-icon>
              <mat-icon (click)="openEditFlow(element._id)" [matTooltip]="'Edit Flow' | translate">edit</mat-icon>
              <mat-icon (click)="copyFlow(element._id, element.name)" [matTooltip]="'Copy this flow' | translate">
                content_copy</mat-icon>
              <mat-icon (click)="editDiagram(element._id)" [matTooltip]="'Edit Diagram' | translate">account_tree
              </mat-icon>
              <span class="material-symbols-outlined tags" [matTooltip]="'Add Tags' | translate"
                (click)="showTags(element.tags, element._id, element.flow)">
                collections_bookmark
              </span>
              <mat-icon (click)="deleteFlow(element._id)" [matTooltip]="'Delete Flow' | translate">delete</mat-icon>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="flowTableDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: flowTableDisplayedColumns;"></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons (page)="getFlows($event)"
          [pageIndex]="pageIndex" [pageSize]="pageSize">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>