import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { DiagramComponent } from '../../features/dashboard/diagram/diagram.component';
import { DiagramService } from '../services/diagram.service';

@Injectable({
    providedIn: 'root'
})
export class CanDeactivateDiagramGuard implements CanDeactivate<any> {

    constructor(private diagramService: DiagramService) { }

    canDeactivate(
        component: DiagramComponent,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        // Perform any necessary action before leaving the current route here
        if (component.diagramChanged) {
            // Display a confirmation dialog
            this.diagramService.diagramChangedSubject.next(nextState);
            return false;
            // return confirm('Are you sure you want to leave?');
        } else {
            return true;
        }
    }
}
