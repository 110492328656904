import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
    transform(text: string, maxChars: number): string {
        if (text) {
            if (text.length > maxChars) {
                return text.slice(0, maxChars - 3) + '...';
            } else {
                return text;
            }
        }
        else return '';
    }
}
