<div class="container" fxLayout="row" fxLayoutAlign="center none">
    <div fxFlex="95%">
        <div class="container" fxLayout="column">
            <div fxLayout="row" fxLayoutGap="0.5vw" fxLayoutAlign="end end">
                <mat-icon style="cursor: pointer;" (click)="addCharacter()"
                    [matTooltip]="'Create Character' | translate">
                    add_circle
                </mat-icon>
            </div>
            <div fxFlex class="no-records animate">
                <table mat-table [dataSource]="datasource" class="mat-elevation-z8 table" *ngIf="datasource">
                    <ng-container matColumnDef="pictureLink">
                        <th mat-header-cell *matHeaderCellDef style="width: 60px;"></th>
                        <td mat-cell *matCellDef="let element">
                            <img class="circle" [src]="element.pictureLink || defaultPictureLink"
                                style="width: 30px; height: 30px;">
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef> {{'Name' | translate}} </th>
                        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                    </ng-container>
                    <ng-container matColumnDef="gender">
                        <th mat-header-cell *matHeaderCellDef style="width: 10%;"> {{'Gender' | translate}} </th>
                        <td mat-cell *matCellDef="let element" class="capitalize"> {{element.gender}} </td>
                    </ng-container>
                    <ng-container matColumnDef="age">
                        <th mat-header-cell *matHeaderCellDef style="width: 10%;"> {{'Age' | translate}} </th>
                        <td mat-cell *matCellDef="let element"> {{element.age}} </td>
                    </ng-container>
                    <ng-container matColumnDef="tools">
                        <th mat-header-cell *matHeaderCellDef style="width: 20%;"></th>
                        <td mat-cell *matCellDef="let element" class="tools">
                            <mat-icon (click)="editCharacter(element._id)" [matTooltip]="'Edit Character' | translate">
                                edit</mat-icon>
                            <mat-icon (click)="deleteCharacter(element._id)" [matTooltip]="'Delete Character' |
                                translate">
                                delete
                            </mat-icon>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>

                <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons>
                </mat-paginator>
            </div>
        </div>
    </div>
</div>