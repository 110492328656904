<div class="kt-portlet">
    <div class="kt-portlet__head kt-portlet__head__custom">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">{{data.title}}</h3>
            <span class="kt-portlet__head-icon kt-hide">
                <i class="la la-gear"></i>
            </span>
        </div>
    </div>
    <div class="kt-form">
        <div class="kt-portlet__body container">
            <mat-spinner [diameter]="50" class="loader" *ngIf="loading"></mat-spinner>
            <div fxLayout="row" *ngIf="!loading" fxLayoutGap="0.5vw">
                <div fxFlex fxLayout="column" fxLayoutGap="1vw" fxGrow="0" fxLayoutAlign="start center">
                    <figure class="card-profile-image" fxFlex fxGrow="0">
                        <img [src]="pictureLink" (click)="showImageCropper()"
                            style="width: 100px; height:100px; cursor: pointer;"
                            class="circle z-depth-2 responsive-img activator">
                    </figure>
                    <button mat-raised-button fxGrow="0" fxFlex (click)="showImageCropper()">{{'Select Image' |
                        translate}}</button>
                </div>
                <div fxLayout="column" fxLayoutGap="0.5vw" style="width: 100%;">
                    <mat-form-field appearance="fill" fxFlex>
                        <mat-label>{{'Name' | translate}}</mat-label>
                        <input maxlength="50" matInput placeholder="" required [formControl]="nameFormControl">
                        <mat-error *ngIf="nameFormControl.hasError('required')">
                            {{'Name is required' | translate}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill" fxFlex>
                        <mat-label>{{'Gender' | translate}}</mat-label>
                        <mat-select name="gender" [formControl]="genderFormControl">
                            <mat-option *ngFor="let gender of ['male','female','other']" [value]="gender"
                                class="gender">
                                {{gender}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="genderFormControl.hasError('required')">
                            {{'Gender is required' | translate}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill" fxFlex>
                        <mat-label>{{'Age' | translate}}</mat-label>
                        <input matInput placeholder="" type="number" min="1" max="100" required
                            [formControl]="ageFormControl">
                        <mat-error *ngIf="ageFormControl.hasError('required')">
                            {{'Age is required' | translate}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{'Description' | translate}}</mat-label>
                        <textarea matInput placeholder="" rows="10" [formControl]="descriptionFormControl"></textarea>
                        <mat-error *ngIf="descriptionFormControl.hasError('required')">
                            {{'Description is required' | translate}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit text-right">
            <div class="kt-form__actions kt-form__actions--solid">
                <br />
                <button mat-raised-button (click)="closeDialog()">{{'Cancel' |
                    translate}}</button>&nbsp;
                <button mat-raised-button color="primary" *ngIf="!characterId"
                    [disabled]="!(nameFormControl.valid && descriptionFormControl.valid && ageFormControl.valid && genderFormControl.valid)"
                    (click)="saveCharacter()">{{'Save' |
                    translate}}</button>
                <button mat-raised-button color="primary" *ngIf="characterId"
                    [disabled]="!(nameFormControl.valid && descriptionFormControl.valid && ageFormControl.valid && genderFormControl.valid)"
                    (click)="updateCharacter()">{{'Update' |
                    translate}}</button>
            </div>
        </div>
    </div>
</div>